import { Container, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, CircularProgress, Button, Box, TextareaAutosize } from "@material-ui/core";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import AppLayout from "../../layout/appLayout";
import { dietPlanDays } from '../../json/menu'
import * as yup from "yup";
import { useEffect, useState } from "react";
import Fetch from "../../common/fetch";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useParams } from "react-router-dom";
import { arrayString } from "../../common/utilits";
import { Delete, Add } from "@material-ui/icons";
import { Stack } from "@mui/material";
import Back from "../../component/backIco";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    days: {
        boxShadow: 'unset',
        borderColor: '#a2a2a2 !important',
        color: '#717171 !important',
        fontSize: '13px !important',
        padding: '7px 22px !important',
        [theme.breakpoints.up("md")]: {
            minWidth: "100px !important",
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: "115px !important",
        },
        '&:hover': {
            backgroundColor: '#242b58 !important',
            color: '#fff !important'
        },
        '&.active': {
            backgroundColor: '#242b58',
            color: '#fff !important',
            '&:hover': {
                backgroundColor: '#242b58',
                color: '#fff !important'
            },
        }
    }
}));
const days = ['Breakfast', 'Lunch', 'Evening Snacks', 'Dinner']
let valdationErr = {
    user: yup
        .string()
        .required("User is required."),
}
// const validationSchema = yup.object().shape({
//     user: yup
//         .string()
//         .required("User is required."),
//     data: yup.array().of(yup.object().shape({
//         dietTime: yup.string()?.required('Meal is required'),
//         dietDetails: yup.string().required('Description is required'), // these constraints take precedence
//     })),
// });

export default function AddDietCoach() {
    let navigate = useNavigate();
    const [tab, setTab] = useState(1)
    const validationSchema = yup.object().shape({
        ...valdationErr,
    });
    const param = useParams()
    const [userName, setUserName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [userList, setUserList] = useState([])
    const [dateState, setDateState] = useState([
        {
            weekdays: 1,
            data: [
                {
                    dietTime: "",
                    dietDetails: '',
                }
            ]
        },
        {
            weekdays: 2,
            data: [
                {
                    dietTime: "",
                    dietDetails: '',
                }
            ]
        },
        {
            weekdays: 3,
            data: [
                {
                    dietTime: "",
                    dietDetails: '',
                }
            ]
        },
        {
            weekdays: 4,
            data: [
                {
                    dietTime: "",
                    dietDetails: '',
                }
            ]
        },
        {
            weekdays: 5,
            data: [
                {
                    dietTime: "",
                    dietDetails: '',
                }
            ]
        },
        {
            weekdays: 6,
            data: [
                {
                    dietTime: "",
                    dietDetails: '',
                }
            ]
        },
        {
            weekdays: 7,
            data: [
                {
                    dietTime: "",
                    dietDetails: '',
                }
            ]
        }
    ])
    const classes = useStyles();
    useEffect(() => {
        loaduser()
        setTab(moment().day())
    }, [param])
    const handleTab = (id) => {
        setTab(id);
    }
    const loaduser = () => {
        if (param?.id !== 'add') {
        Fetch(`sports/diet-retrive/${param?.id}/`).then(res => {
            if (res.status) {
                const val = res.data?.length ? res.data[0]?.user_name : ''
                setUserName(val)
                setDateState(res.data?.map(items => {
                    return {
                        weekdays: items.id, data: items.diet_plan?.length ? items.diet_plan?.map(plan => { return { dietDetails: plan.dietDetails, dietTime: plan.dietTime } }) : [
                            {
                                dietTime: "",
                                dietDetails: '',
                            }
                        ]
                    }
                }))
            }
        })
    }
        Fetch('sports/user/user-listing/').then(res => {
            if (res.status) {
                setUserList(res.data)
            }
        })
    }
    const onSubmit = () => {
        if (formik.isValid) {
            setIsLoading(true)
            let val = { ...formik.values }
            delete val.user
            Fetch(`sports/${param.id === "add" ? 'create-diet-plan/' : `update-diet-plan/`}${formik.values.user}/`, [...val.data], { method: 'post' }).then(res => {
                setIsLoading(false)
                if (res.status) {
                    toast.success(`Plan ${param.id === "add" ? 'Added' : 'Updated'} Succesfully.`, {
                        hideProgressBar: false,
                        position: "top-right",
                        autoClose: 3000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        navigate("/plan/");
                    }, 3000)
                } else {
                    let resErr = arrayString(res)
                    toast.error(resErr.error, {
                        hideProgressBar: false,
                        position: "top-right",
                        autoClose: 3000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
        }
    }
    const formik = useFormik({
        initialValues: {
            user: param.id === "add" ? '' : Number(param.id),
            data: dateState,
        },
        validateOnChange: true,
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });
    // console.log(formik.values, dateState, 'values===');
    return <AppLayout>
        <ToastContainer />
        <Container>
            <h3 className="flex align-center" style={{ padding: "10px 10px 10px 0" }}><Back /> {param.id === 'add' ? 'Add' : 'Edit'} Plan</h3>
            <Paper elevation={3}>
                <div
                    className={classes.root}
                    style={{ padding: "20px", marginBottom: "80px" }}
                >
                    <form method="POST" noValidate onSubmit={formik.handleSubmit} autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                            <InputLabel
                                            id="user"
                                            className="Input"
                                            style={{
                                                color: "rgba(12,11,69,255)",
                                                display: "flex",
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            User
                                        </InputLabel>
                                {/* {
                                    param.id === "add" ? <> */}
                                        <Select
                                            error={Boolean(
                                                formik.touched.user && formik.errors.user
                                            )}
                                            fullWidth
                                            variant="outlined"
                                            required
                                            disabled={param.id !== "add"}
                                            helperText={formik.touched.user && formik.errors.user}
                                            name="user"
                                            value={formik.values.user}
                                            className={param.id !== "add" ?'selectDisable':''}
                                            style={{ marginTop: "16px", }}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                            }}
                                            onBlur={formik.handleBlur}
                                            displayEmpty
                                        >
                                            <MenuItem disabled value="">
                                                <em>--Select--</em>
                                            </MenuItem>
                                            {
                                                userList?.map(items => <MenuItem key={items.id} value={items.id}>{items.name + ` (${items.email})`}</MenuItem>)
                                            }
                                        </Select>
                                    {/* </>
                                :
                                <Box className="userName-diet">{userName}</Box>
                                } */}
                                {/* {mes && <div style={{ color: "red" }}>{mes}</div>} */}
                                {formik.touched.user && (
                                    <FormHelperText error={formik.touched.user}>{formik.errors.user}</FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        <Stack direction="row" className="diet-tab" spacing={2} style={{ justifyContent: "center", marginBottom: '25px', marginTop: '15px' }}>
                            {
                                dietPlanDays?.map(items => {
                                    return <Button variant="outlined" className={`${classes.days} ${tab === items.id ? 'active' : ''}`} onClick={() => handleTab(items.id)} value={items.id} key={items.id}>
                                        {items.name}
                                    </Button>
                                })
                            }
                        </Stack>
                        <FormikProvider value={formik}>
                            <FieldArray name="data"
                                render={arrayHelpers => (
                                    <Grid container spacing={2}>
                                        {
                                            formik?.values.data.length > 0 &&
                                            formik?.values.data.map((friend, key) => (
                                                friend.weekdays === tab && <FieldArray
                                                    name={`data.${key}.data`}
                                                    render={({ insert, remove, push }) => (
                                                        formik?.values.data[key]?.data.length > 0 &&
                                                        formik?.values.data[key]?.data.map((friend, index) => (
                                                            <Grid item sm={12} md={formik?.values.data[key]?.data.length > 1 ? 6 : 12}>
                                                                <InputLabel
                                                                    className="Input"
                                                                    style={{
                                                                        color: "rgba(12,11,69,255)",
                                                                        display: "flex",
                                                                        fontSize: "15px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    {/* Meal {index + 1} */}
                                                                    Areas
                                                                </InputLabel>
                                                                <Select
                                                                    error={Boolean(
                                                                        formik.errors?.data?.length && formik.touched?.data?.length && formik?.values.data[key]?.data.length && formik.touched?.data[key]?.data[index]?.dietTime && formik.errors?.data[key]?.data[index]?.dietTime
                                                                    )}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    required
                                                                    helperText={formik.errors?.data?.length && formik.touched?.data?.length && formik?.values.data[key]?.data.length && formik.touched?.data[key]?.data[index]?.dietTime && formik.errors?.data[key]?.data[index]?.dietTime}
                                                                    // name="dietTime"
                                                                    name={`data.${key}.data.${index}.dietTime`}
                                                                    value={formik.values?.data[key]?.data[index]?.dietTime}
                                                                    style={{ marginTop: "16px" }}
                                                                    onChange={(e) => {
                                                                        formik.handleChange(e);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    displayEmpty
                                                                >
                                                                    <MenuItem disabled value="">
                                                                        <em>--Select--</em>
                                                                    </MenuItem>
                                                                    {
                                                                        days?.map((days, keys) => {
                                                                            return <MenuItem value={days}>{days}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                                {formik.errors?.data?.length && formik.touched?.data?.length && formik?.values.data[key]?.data.length && formik.touched?.data[key]?.data[index]?.dietTime && (
                                                                    <FormHelperText error={formik.touched?.data[key]?.data[index]?.dietTime}>{formik.errors?.data[key]?.data[index]?.dietTime}</FormHelperText>
                                                                )}
                                                                <TextareaAutosize
                                                                    minRows={4}
                                                                    className={`textare-input ${Boolean(
                                                                        formik.errors?.data?.length && formik.touched?.data?.length && formik?.values.data[key]?.data.length && formik.touched?.data[key]?.data[index]?.dietDetails && formik.errors?.data[key]?.data[index]?.dietDetails
                                                                    ) ? 'Mui-error' : ''}`}
                                                                    margin="normal"
                                                                    placeholder="Meal Description"
                                                                    required
                                                                    fullWidth
                                                                    name={`data.${key}.data.${index}.dietDetails`}
                                                                    value={formik.values?.data[key]?.data[index]?.dietDetails}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    type="text"
                                                                    variant="outlined"
                                                                />
                                                                {formik.errors?.data?.length && formik.touched?.data?.length && formik?.values.data[key]?.data.length && formik.touched?.data[key]?.data[index]?.dietDetails && (
                                                                    <FormHelperText error={formik.touched?.data[key]?.data[index]?.dietDetails}>{formik.errors?.data[key]?.data[index]?.dietDetails}</FormHelperText>
                                                                )}
                                                                <div className="flex justify-end">
                                                                    {formik.values.data[key]?.data?.length > 1 ? <Button
                                                                        type="button"
                                                                        disabled={formik.values.data[key]?.data?.length === 1}
                                                                        className="secondary"
                                                                        style={{
                                                                            background: '#cd4545',
                                                                            color: '#fff'
                                                                        }}
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        Remove
                                                                    </Button> : null}
                                                                    {formik.values.data[key]?.data?.length >= 4 ? null :
                                                                        formik.values.data[key]?.data?.length === (index + 1) && <Button
                                                                            type="button"
                                                                            style={{
                                                                                background: '#242b58',
                                                                                color: '#fff',
                                                                                marginLeft: '10px'
                                                                            }}
                                                                            className="secondary"
                                                                            onClick={() => push({
                                                                                dietTime: "",
                                                                                dietDetails: ''
                                                                            })}
                                                                        >
                                                                            {/* Add Meal {(index + 2)} + */}
                                                                            Add Plan
                                                                        </Button>}
                                                                </div>
                                                            </Grid>
                                                        ))
                                                    )}
                                                />
                                            ))
                                        }
                                    </Grid>
                                )}
                            />
                        </FormikProvider>
                        <Grid item sm={12} md={12}>
                            <div style={{ textAlign: "center", marginTop: "100px" }}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{
                                        backgroundColor: "#232b58",
                                        textTransform: 'uppercase',
                                        color: "#fff",
                                        borderRadius: "25px",
                                        width: "290Px",
                                        padding: "13px",
                                        fontSize: "17px",
                                    }}
                                    className="btn-submit"
                                >
                                    {isLoading ? <CircularProgress Shrink /> : `${param.id === 'add' ? 'Add Plan' : 'Update'}`}
                                </Button>
                            </div>
                        </Grid>
                    </form>
                </div>
            </Paper>
        </Container>
    </AppLayout>
}