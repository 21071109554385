import AppLayout from "../../layout/appLayout";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import { Container } from "@material-ui/core";
import { Input } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '80vh',
        boxShadow: '0px 0px 10px 0px rgb(0, 0, 0, 0.27)'
    },
    topBarSelectedChat: {
        paddingTop: '3px',
        paddingBottom: '3px'
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },
      sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "unset",
        },
      },
    searchInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '20px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '11.5px 14px'
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 14px) scale(1)'
        }
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    chatList: {
        '& .MuiListItem-root': {
            borderBottom: '1px solid #e0e0e0',
            '&:last-child': {
                border: '0'
            }
        }
    },
    messageArea: {
        height: '55vh',
        overflowY: 'auto'
    },
    chatname: {
        margin: 0,
        '& .MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 600
        }
    },
    chattime: {
        margin: 0,
        '& .MuiTypography-root': {
            fontSize: '11px',
            fontWeight: 600,
            color: '#232955'
        }
    },
    chatMsg: {
        margin: 0,
        '& .MuiTypography-root': {
            fontSize: '13px',
        }
    },
    chatMessageLeft: {
        background: '#232955',
        padding: '12px 20px',
        borderRadius: '0px 15px 15px 15px',
        color: '#fff',
        width: 'fit-content',
        '& .MuiListItemText-primary': {
            fontSize: '13px'
        },
        '& .MuiTypography-colorTextSecondary': {
            color: 'rgb(129 144 255)',
            fontSize: '11px'
        }
    },
    chatMessageRight: {
        background: '#f8f7ff',
        padding: '12px 20px',
        borderRadius: '15px 15px 0px 15px',
        color: '#7f7e81',
        '& .MuiListItemText-primary': {
            fontSize: '13px'
        },
        '& .MuiTypography-colorTextSecondary': {
            color: 'rgb(129 144 255)',
            fontSize: '11px'
        }
    },
    chatSendBtn:{
        background: '#232955',
        boxShadow:'unset'
    },
    chatMessageInput:{
        background:'#efefef',
        borderRadius: '30px',
        padding: '10px 15px',
        width: 'calc(100% - 10px) !important',
        '&:before,&:after':{
            border:'unset !important',
        }
    }
}));

const Chat = () => {
    const classes = useStyles();

    return (
        <AppLayout>
            <Container>
                <Grid container>
                    <Grid item xs={12} >
                        <h3 style={{
                            padding: "0px",
                            marginTop: "30px",
                            marginBottom: "20px",
                            padding: "12px 0px",
                        }}>Chat</h3>
                    </Grid>
                </Grid>
                <Grid container component={Paper} className={classes.chatSection}>
                    <Grid item xs={12} md={3} className={classes.borderRight500}>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField id="outlined-basic-email" className={classes.searchInput} placeholder="Search" variant="outlined" fullWidth />
                        </Grid>
                        <Divider />
                        <List className={classes.chatList}>
                            <ListItem button key="RemySharp">
                                <ListItemIcon>
                                    <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
                                </ListItemIcon>
                                <Box className="w-100">
                                    <Box className="w-100 flex align-center justify-between">
                                        <ListItemText className={classes.chatname} primary="Remy Sharp">Remy Sharp</ListItemText>
                                        <ListItemText className={classes.chattime} secondary="06:50 PM" align="right"></ListItemText>
                                    </Box>
                                    <ListItemText className={classes.chatMsg} secondary="Lorem Ipsum is simply dummy text" />
                                </Box>
                            </ListItem>
                            <ListItem button key="Alice">
                                <ListItemIcon>
                                    <Avatar alt="Alice" src="https://material-ui.com/static/images/avatar/3.jpg" />
                                </ListItemIcon>
                                <Box className="w-100">
                                    <Box className="w-100 flex align-center justify-between">
                                        <ListItemText className={classes.chatname} primary="Alice">Alice</ListItemText>
                                        <ListItemText className={classes.chattime} secondary="06:50 PM" align="right"></ListItemText>
                                    </Box>
                                </Box>
                            </ListItem>
                            <ListItem button key="CindyBaker">
                                <ListItemIcon>
                                    <Avatar alt="Cindy Baker" src="https://material-ui.com/static/images/avatar/2.jpg" />
                                </ListItemIcon>
                                <Box className="w-100">
                                    <Box className="w-100 flex align-center justify-between">
                                        <ListItemText className={classes.chatname} primary="Cindy Baker">Cindy Baker</ListItemText>
                                        <ListItemText className={classes.chattime} secondary="06:50 PM" align="right"></ListItemText>
                                    </Box>
                                </Box>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={9} className={classes.sectionDesktop}>
                        <List className={classes.topBarSelectedChat}>
                            <ListItem button key="RemySharp">
                                <ListItemIcon>
                                    <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
                                </ListItemIcon>
                                <ListItemText primary="John Wick"></ListItemText>
                            </ListItem>
                        </List>
                        <Divider />
                        <List className={classes.messageArea}>
                            <ListItem key="1">
                                <Grid container justifyContent='flex-end'>
                                    <Grid item xs={10} className='flex justify-end'>
                                        <Box className={classes.chatMessageRight}>
                                            <ListItemText align="right" primary="Hey man, What's up ?"></ListItemText>
                                            <ListItemText align="right" secondary="09:30"></ListItemText>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem key="2">
                                <Grid container>
                                    <Grid item>
                                        <Box sx={{ mr: 2 }}>
                                            <Avatar alt="Remy Sharp" align="right" src="https://material-ui.com/static/images/avatar/1.jpg" />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={10} >
                                        <ListItemText className={classes.chatname} primary="Remy Sharp">Remy Sharp</ListItemText>
                                        <Box className={classes.chatMessageLeft}>
                                            <ListItemText align="left" primary="Hey, Iam Good! What about you ?"></ListItemText>
                                            <ListItemText align="right" secondary="09:31"></ListItemText>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem key="3">
                                <Grid container justifyContent='flex-end'>
                                    <Grid item xs={10} className='flex justify-end'>
                                        <Box className={classes.chatMessageRight}>
                                            <ListItemText align="right" primary="Cool. i am good, let's catch up!"></ListItemText>
                                            <ListItemText align="right" secondary="10:30"></ListItemText>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                        <Divider />
                        <Grid container style={{ padding: '20px' }}>
                            <Grid item xs={11}>
                                <Input id="outlined-basic-email" placeholder="Type your message..." className={classes.chatMessageInput} fullWidth />
                            </Grid>
                            <Grid xs={1} align="right">
                                <Fab color="primary" aria-label="add" className={classes.chatSendBtn}><SendIcon /></Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </AppLayout>
    );
}

export default Chat;