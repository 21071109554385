export function arrayString(value) {
    let a = typeof value
    if (a == 'object') {
        let error = {}
        for (const val of Object.entries(value)) {
            const [key, value] = val
            if (key != 'status') {
                if (value?.length && Array.isArray(value)) {
                    error['error'] = value[0]
                    return error
                } else {
                    error['error'] = value || value[1] || 'Something went wrong'
                    return error
                }
            }
        }
        return error
    }
    else
        return value;
}
export const onKeyPress = (evt, reg = null) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = reg ? reg : /^[0-9\b]+$/;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
};
export const paginationSize = 10