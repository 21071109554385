import { DialogTitle, MenuItem, InputLabel, TextField, IconButton, Button, Dialog, DialogActions, DialogContent, FormHelperText } from '@mui/material';
import { CircularProgress, List, ListItem, Select, OutlinedInput, Checkbox } from '@material-ui/core';
import Fetch from '../../common/fetch';
import { useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from "yup";
import { toast } from 'react-toastify';
import { arrayString } from '../../common/utilits';
import { makeStyles } from "@material-ui/core/styles";
import { Upload, Clear } from '@mui/icons-material';

const validationSchema = yup.object().shape({
    title: yup
        .string()
        .required("Title is required."),
    upload_post: yup.array().min(1, 'Please upload the file.')
});
const useStyles = makeStyles((theme) => ({
    filename: {
        fontSize: '12px',
        padding: 0,
        paddingRight: '10px',
        '& svg': {
            width: '20px',
            height: '20px'
        },
        '& button': {
            padding: 0,
            paddingLeft: '10px',
            minWidth: 'auto',
        }
    },
}))

export default function UploadLibrary({ openUpload, handleUpload, userList, loadLibraryList }) {
    const classes = useStyles();
    const hiddenFileDocument = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        upload_post: [],
        title: '',
        user_ids: []
    })
    const onSubmit = (e) => {
        if (formik.isValid) {
            setIsLoading(true);
            let val = {
                ...state,
                user_ids:state.user_ids?.map(items=>JSON.parse(items)?.id)
            }
            Fetch('sports/library/', val, { method: 'post', inFormData: true }).then(res => {
                setIsLoading(false);
                if (res.status) {
                    handleUpload()
                    setState({
                        upload_post: [],
        title: '',
        user_ids: []
                    })
                    loadLibraryList()
                    toast.success("Library Added Succesfully.", {
                        hideProgressBar: false,
                        position: "top-right",
                        autoClose: 3000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    let resErr = arrayString(res)
                    toast.error(resErr.error, {
                        hideProgressBar: false,
                        position: "top-right",
                        autoClose: 3000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
        }
    }
    const handleClickDocument = () => {
        hiddenFileDocument.current.click();
    };
    const handleFileDelete = (key, name) => {
        let data = Object.values(state[name])
        data = data?.filter((items, index) => index !== key)
        setState({
            ...state,
            [name]: data
        })
    }
    const onFileListChange = (e) => {
        setState({
            ...state,
            upload_post: [...e.target.files],
        });
    };
    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };
    const formik = useFormik({
        initialValues: state,
        validateOnChange: true,
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });
    return <Dialog className='smDialog' open={openUpload} onClose={handleUpload}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle>Upload</DialogTitle>
            <IconButton onClick={handleUpload}>
                <CloseIcon style={{ color: "rgba(12,11,69,255)" }} />
            </IconButton>
        </div>
        <form onSubmit={formik.handleSubmit}>
            <DialogContent>
                <InputLabel
                    id="user"
                    className="Input"
                    style={{
                        color: "rgba(12,11,69,255)",
                        display: "flex",
                        fontSize: "15px",
                        fontWeight: "bold",
                    }}
                >
                    Title
                </InputLabel>
                <TextField
                    id="sport_center"
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                        formik.touched.title &&
                        formik.errors.title
                    )}
                    margin="normal"
                    fullWidth
                    helperText={
                        formik.touched.title &&
                        formik.errors.title
                    }
                    name="title"
                    label="title"
                    value={formik.values.title}
                    onBlur={formik.handleBlur}
                    onKeyUp={onChange}
                    onChange={formik.handleChange}
                    variant="outlined"
                    type="text"
                />
                <InputLabel
                    id="user"
                    className="Input"
                    style={{
                        color: "rgba(12,11,69,255)",
                        display: "flex",
                        fontSize: "15px",
                        fontWeight: "bold",
                    }}
                >
                    Users
                </InputLabel>
                <Select
                    error={Boolean(
                        formik.touched.user_ids && formik.errors.user_ids
                    )}
                    fullWidth
                    variant="outlined"
                    multiple
                    input={<OutlinedInput placeholder="Tag" />}
                    renderValue={(selected) => !selected.length ? <em>Users</em> : selected?.map(item => JSON.parse(item)?.name)?.join(', ')}
                    helperText={formik.touched.user_ids && formik.errors.user_ids}
                    name="user_ids"
                    value={formik.values.user_ids}
                    style={{ marginBottom: "16px", marginTop: "8px" }}
                    onChange={(e) => {
                        formik.handleChange(e);
                        onChange(e)
                    }}
                    onBlur={formik.handleBlur}
                >
                    {
                        userList?.map(items => <MenuItem value={JSON.stringify(items)}>
                            <Checkbox checked={state.user_ids?.indexOf(JSON.stringify(items)) > -1} />
                            {items?.name}
                        </MenuItem>)
                    }
                </Select>
                {formik.touched.user_ids && (
                    <FormHelperText error={formik.touched.user_ids}>{formik.errors.user_ids}</FormHelperText>
                )}
                <div
                    style={{
                        padding: "20px",
                        textAlign: "center",
                        border: '3px dashed #929292'
                    }}
                >
                    <div className="profile-Documents"  >
                        <label htmlFor="fileDocs">
                            <Upload className="profile-dummy-ico" />
                            <p>Upload your file</p>
                            <Button
                                variant="contained"
                                type="button"
                                onClick={handleClickDocument}
                                style={{
                                    backgroundColor: "#232b58",
                                    textTransform: 'uppercase',
                                    color: "#fff",
                                    borderRadius: "25px",
                                    padding: "10px 30px",
                                    fontSize: "17px",
                                }}
                                className="btn-submit"
                            >
                                Browse File
                            </Button>
                            <input
                                type="file"
                                ref={hiddenFileDocument}
                                id="fileDocs"
                                className="d-none"
                                name="document"
                                accept="image/png, image/jpeg, image/jpg, video/*"
                                onChange={onFileListChange}
                            />
                        </label>
                    </div>
                </div>
                <List>
                    {
                        Object.values(state.upload_post)?.map((item, key) => {
                            return <ListItem className={classes.filename} key={key}>{item.name} <Button onClick={() => handleFileDelete(key, 'upload_post')}><Clear /></Button></ListItem>
                        })
                    }
                </List>
                {formik.touched.upload_post && <FormHelperText error={formik.touched.upload_post}>{formik.errors.upload_post}</FormHelperText>}
            </DialogContent>
            <DialogActions style={{ justifyContent: "center", marginBottom: "15px" }}>
                <Button variant="contained" type='submit' style={{
                    backgroundColor: "rgba(12,11,69,255)",
                    color: "white", borderRadius: " 22px 22px", padding: "10px 65px"
                }}>{isLoading ? <CircularProgress /> : 'Upload'}</Button>
            </DialogActions>
        </form>
    </Dialog>
}