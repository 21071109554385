import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Paper, Grid, TextField, InputLabel, Container, MenuItem, Select, ListItem, List } from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import AppLayout from "../../layout/appLayout";
import CircularProgress from "@mui/material/CircularProgress";
import { Clear, Upload } from "@mui/icons-material";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { arrayString } from "../../common/utilits";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from "moment";
import Back from "../../component/backIco";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dateInput: {
    marginTop: "8px !important"
  },
  filename: {
    fontSize: '12px',
    padding: 0,
    paddingRight: '10px',
    '& svg': {
      width: '20px',
      height: '20px'
    },
    '& button': {
      padding: 0,
      paddingLeft: '10px',
      minWidth: 'auto',
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
const emailRegx = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
let validateError = {
  email: yup
    .string()
    .email("Email is invalid.")
    .required("Email is required.")
    .matches(emailRegx, "Invalid Email ID..."),
  phone_no: yup
    .string()
    .matches(phoneRegExp, "Phone number must contains only digits.")
    .min(10, "Phone number should not be less than 10 digits.")
    .max(10, "Phone number should not be more than 10 digits.")
    .required("Phone number is required."),
  location: yup
    .string()
    .max(50, "Must be 50 characters or less.")
    .required("Location is required."),
  center_name: yup
    .string()
    .max(25, "Must be 25 characters or less.")
    .required("Centre is required.")
    .matches(/^[A-Za-z ]*$/, "Only alphabets are required."),
  timefrom: yup.string().required("Opening Time is required."),
  timeto: yup.string().required("Closing Time is required."),
}

const AddNewSportsCenter = () => {
  let navigate = useNavigate();
  const classes = useStyles();
  const hiddenFileLogo = useRef(null);
  const hiddenFileDocument = useRef(null);
  const param = useParams()
  const validationSchema = yup.object().shape(validateError);
  const [error, seterror] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState({
    logo: [],
    document: []
  });
  const [state, setState] = useState({
    email: "",
    name: "",
    phone_no: "",
    center_name: "",
    gender: '',
    location: "",
    timefrom: "",
    timeto: "",
    logo: [],
    document: [],
  });
  useEffect(() => {
    if (param.id !== 'add') {
      loadUser(param.id)
    }
    setState({
      name: "",
      email: "",
      phone_no: "",
      center_name: "",
      location: "",
      timefrom: "",
      timeto: "",
      logo: [],
      document: [],
    })
  }, [param])
  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  };
  const onFileListChange = (e) => {
    setFiles({
      ...files,
      [e.target.name]: [...e.target.files],
    });
    setState({
      ...state,
      [e.target.name]: [...e.target.files],
    });
  };
  const onSubmit = (e) => {
    if (formik.isValid) {
      setIsLoading(true);
      let val = { ...state,timefrom:moment(state.timefrom).format('HH:mm:ss'),timeto: moment(state.timeto).format('HH:mm:ss')}
      if (param.id !== 'add') {
        delete val.email
      }
      Fetch(`sports/sports-center/${param.id === 'add' ? '' : param.id + '/'}`, val, { method: param.id === 'add' ? 'post' : 'patch', inFormData: true }).then(res => {
        setIsLoading(false);
        if (res.status) {
          toast.success(`Centre ${param.id === 'add' ? 'Added' : 'Updated'} Successfully.`, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/centre/");
          }, 3000)
        } else {
          let resErr = arrayString(res)
          toast.error(resErr.error, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
    }
  };
  const loadUser = (id) => {
    Fetch(`sports/sports-center/${id}/`).then(res => {
      if (res.status) {
        const { data } = res
        setState({
          ...state,
          email: data?.email,
          timefrom: moment(data?.timefrom, "HH:mm:ss").format() || "",
          timeto: moment(data?.timeto, "HH:mm:ss").format() || "",
          phone_no: data?.phone_no,
          center_name: data?.center_name || '',
          location: data?.location || '',
        });
        setFiles({
          ...files,
          logo: { file: { name: data?.logo } },
          document: { file: { name: data?.document } },
        });
      }
    })
  };
  const handleClickLogo = () => {
    hiddenFileLogo.current.click();
  };
  const handleClickDocument = () => {
    hiddenFileDocument.current.click();
  };
  const handleFileDelete = (key, name) => {
    let data = Object.values(files[name])
    let data1 = Object.values(state[name])
    data = data?.filter((items, index) => index !== key)
    data1 = data1?.filter((items, index) => index !== key)
    setState({
      ...state,
      [name]: data
    })
    setFiles({
      ...files,
      [name]: data
    })
  }
  const onDateChange = (val, name) => {
    setState({
      ...state,
      [name]: val
    })
  }
  const formik = useFormik({
    initialValues: state,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <AppLayout style={{ marginBottom: "50px" }}>
      <ToastContainer />
      <Container>
        <h3 className="flex align-center" style={{ padding: "10px 10px 10px 0px" }}><Back/> {param.id === 'add' ? 'Add' : 'Edit'} Centre</h3>
        <Paper elevation={3}>
          <div
            className={classes.root}
            style={{ padding: "20px", marginBottom: "80px" }}
          >
            <form method="POST" noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    id="center_name"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Centre
                  </InputLabel>
                  <TextField
                    id="sport_center"
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                      formik.touched.center_name &&
                      formik.errors.center_name
                    )}
                    margin="normal"
                    fullWidth
                    helperText={
                      formik.touched.center_name &&
                      formik.errors.center_name
                    }
                    name="center_name"
                    value={formik.values.center_name}
                    onBlur={formik.handleBlur}
                    onKeyUp={onChange}
                    onChange={formik.handleChange}
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Email
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    margin="normal"
                    disabled={param.id !== 'add'}
                    name="email"
                    onBlur={formik.handleBlur}
                    onKeyUp={onChange}
                    onChange={formik.handleChange}
                    type="email"
                    autoComplete="off"
                    value={formik.values.email}
                    variant="outlined"
                  />
                  <p style={{ color: "red", margin: "0px", fontSize: "12px" }}>
                    {error}
                  </p>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Phone No.
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 10 }}
                    error={Boolean(
                      formik.touched.phone_no && formik.errors.phone_no
                    )}
                    margin="normal"
                    required
                    fullWidth
                    helperText={
                      formik.touched.phone_no && formik.errors.phone_no
                    }
                    onBlur={formik.handleBlur}
                    onKeyUp={onChange}
                    value={formik.values.phone_no}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    name="phone_no"
                    variant="outlined"
                    type="tel"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Location
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                      formik.touched.location && formik.errors.location
                    )}
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                    margin="normal"
                    required
                    fullWidth
                    onBlur={formik.handleBlur}
                    value={formik.values.location}
                    onKeyUp={onChange}
                    onChange={formik.handleChange}
                    name="location"
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Opening Time
                  </InputLabel>
                  {/* <Datepicker
                    controls={["time"]}
                    select="range"
                    display="inline"
                    touchUi={true}
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker
                        className={`w-100 ${classes.dateInput}`}
                        slotProps={{
                          textField: {
                            error: Boolean(formik.touched.timefrom && formik.errors.timefrom),
                            helperText: formik.touched.timefrom && formik.errors.timefrom,
                          },
                        }}
                        value={moment(state.timefrom)}
                        fullWidth
                        onChange={(val) => {
                          onDateChange(val, 'timefrom');
                        }}
                        min="00:00"
                        max="12:00"
                        required
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {/* <TextField
                    style={{ cursor: "pointer" }}
                    error={Boolean(
                      formik.touched.timefrom && formik.errors.timefrom
                    )}
                    helperText={
                      formik.touched.timefrom && formik.errors.timefrom
                    }
                    margin="normal"
                    type="time"
                    variant="outlined"
                    value={formik.values.timefrom}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onKeyUp={onChange}
                    onChange={(e) => {
                      formik.handleChange(e);
                      onChange(e);
                    }}
                    id="timefrom"
                    name="timefrom"
                    min="00:00"
                    max="12:00"
                    required
                  ></TextField> */}
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Closing Time
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker
                        className={`w-100 ${classes.dateInput}`}
                        slotProps={{
                          textField: {
                            error: Boolean(formik.touched.timeto && formik.errors.timeto),
                            helperText: formik.touched.timeto && formik.errors.timeto,
                          },
                        }}
                        value={moment(state.timeto)}
                        fullWidth
                        onChange={(val) => {
                          onDateChange(val, 'timeto');
                        }}
                        min="00:00"
                        max="12:00"
                        required
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {/* <TextField
                    style={{ cursor: "pointer" }}
                    error={Boolean(
                      formik.touched.timeto && formik.errors.timeto
                    )}
                    helperText={
                      formik.touched.timeto && formik.errors.timeto
                    }
                    // InputProps={{ inputProps: { min: 0, max: 10 } }}
                    type="time"
                    fullWidth
                    value={formik.values.timeto}
                    variant="outlined"
                    margin="normal"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                      onChange(e);
                    }}
                    onKeyUp={onChange}
                    id="timeto"
                    name="timeto"
                    required
                  ></TextField> */}
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingBottom: "15px",
                    }}
                  >
                    Logo
                  </InputLabel>
                  <label htmlFor="fileProfile"
                    style={{
                      padding: "50px",
                      textAlign: "center",
                      display: 'block',
                      border: '3px dashed #929292'
                    }}
                  >
                    <div className="profile-image">
                      <Upload className="profile-dummy-ico" />
                      <p>Upload Sports Logo</p>
                      <Button
                        variant="contained"
                        type="button"
                        onClick={handleClickLogo}
                        style={{
                          backgroundColor: "#232b58",
                          textTransform: 'uppercase',
                          color: "#fff",
                          borderRadius: "25px",
                          padding: "10px 30px",
                          fontSize: "17px",
                        }}
                        className="btn-submit"
                      >
                        Browse File
                      </Button>
                    </div>
                  </label>
                  <input
                    type="file"
                    id="fileProfile"
                    className="d-none"
                    name="logo"
                    accept="image/*"
                    ref={hiddenFileLogo}
                    onChange={onFileListChange}
                    onBlur={formik.handleBlur}
                  />
                  <em>(Only *.jpeg, *.jpg and *.png images will be accepted)</em>
                  <List>
                    {
                      Object.values(files?.logo)?.map((item, key) => {
                        return item.name && <ListItem className={classes.filename} key={key}>{item.name} <Button className="btn-back" onClick={() => handleFileDelete(key, 'logo')}><Clear /></Button></ListItem>
                      })
                    }
                  </List>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingBottom: "15px",
                    }}
                  >
                    Supporting Document
                  </InputLabel>
                  <div
                    style={{
                      padding: "50px",
                      textAlign: "center",
                      border: '3px dashed #929292'
                    }}
                  >
                    <div className="profile-Documents"  >
                      <label htmlFor="fileDocs">
                        <Upload className="profile-dummy-ico" />
                        <p>Upload Supporting Document</p>
                        <Button
                          variant="contained"
                          type="button"
                          onClick={handleClickDocument}
                          style={{
                            backgroundColor: "#232b58",
                            textTransform: 'uppercase',
                            color: "#fff",
                            borderRadius: "25px",
                            padding: "10px 30px",
                            fontSize: "17px",
                          }}
                          className="btn-submit"
                        >
                          Browse File
                        </Button>
                        <input
                          type="file"
                          ref={hiddenFileDocument}
                          id="fileDocs"
                          className="d-none"
                          name="document"
                          accept=".xlsx,.xls,.doc, .docx,.txt,.pdf"
                          onChange={onFileListChange}
                        />
                      </label>
                    </div>
                  </div>

                  <em>(Only *.pdf and *.docx, *.xlsx files will be accepted)</em>
                  <List className="flex">
                    {
                      Object.values(files.document)?.map((item, key) => {
                        return item.name && <ListItem className={classes.filename} key={key}>{item.name} <Button className="btn-back" onClick={() => handleFileDelete(key, 'document')}><Clear /></Button></ListItem>
                      })
                    }
                  </List>
                  {/* {formik.touched.document &&
                        formik.errors.document && (
                        <FormHelperText className="Mui-error">
                          {formik.errors.document}
                        </FormHelperText>
                      )} */}
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ textAlign: "center", marginTop: "100px" }}>
                    <Button
                      variant="contained"
                      // disabled={isSubmitting}
                      type="submit"
                      style={{
                        backgroundColor: "#232b58",
                        textTransform: 'uppercase',
                        color: "#fff",
                        borderRadius: "25px",
                        width: "290Px",
                        padding: "13px",
                        fontSize: "17px",
                      }}
                      // onClick={(e) => onSubmit(e)}
                      className="btn-submit"
                    >
                      {isLoading ? <CircularProgress Shrink /> : `${param.id === 'add' ? 'Add' : 'Update'} Centre`}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </AppLayout>
  );
};
export default AddNewSportsCenter;