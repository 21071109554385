import React from 'react'
import { Favorite } from '@material-ui/icons';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import CopyrightIcon from '@material-ui/icons/Copyright';


function Footer() {
    return (
        <Container >
             <AppBar position="static" style={{
                backgroundColor: "transparent",
                width: "100%",
                boxShadow:"none",
            }}>
            <p variant="body1" style={{
                textAlign: "center", color: "#999",
            }} >
                Copyright <CopyrightIcon style={{fontSize : "15px"}}/>  2022 Sports App. All rights reserved.
            </p>
            </AppBar>
        </Container>
    )
}

export default Footer
