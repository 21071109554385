import React, { useState, useEffect } from 'react'
import { ImageList, ImageListItem, TextField, Autocomplete, ImageListItemBar, Menu, Checkbox, DialogContentText, MenuItem, DialogTitle, IconButton, Container, Paper, Grid, Button, Dialog, DialogActions, DialogContent, List, ListItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useFormik } from "formik";
import * as yup from "yup";
import "./library.css";
import AppLayout from '../../layout/appLayout';
import { useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, InputLabel, Typography, useMediaQuery } from '@material-ui/core';
import Fetch from '../../common/fetch';
import { toast, ToastContainer } from 'react-toastify';
import { arrayString } from '../../common/utilits';
import Assign from './assign';
import Pagination from "../../component/Page/Pagination";
import { withContext } from '../../context/appContext';
import UploadLibrary from './upload';
import { PlayCircle, Close } from '@mui/icons-material';
const validationSchema = yup.object().shape({
    title: yup
        .string()
        .required("Title is required."),
    upload_post: yup.array().min(1, 'Please upload the file.')
});
const useStyles = makeStyles((theme) => ({
    filename: {
        fontSize: '12px',
        padding: 0,
        paddingRight: '10px',
        '& svg': {
            width: '20px',
            height: '20px'
        },
        '& button': {
            padding: 0,
            paddingLeft: '10px',
            minWidth: 'auto',
        }
    },
    imgList: {
        '&:after': {
            content: '""',
            boxShadow: `rgb(0, 0, 0) 0px -37px 50px 0px inset`,
            position: 'absolute',
            bottom: '0',
            width: '100%',
            height: '100%',
            borderRadius: "10px",
            zIndex: '0',
        }
    },
    menuMore: {
        '@media screen and (max-width: 500px)': {
            '& .MuiPaper-root': {
                maxHeight: '35px'
            },
            '& .MuiList-root': {
                padding: '0 5px',
                '& .MuiButtonBase-root': {
                    fontSize: '12px',
                    lineHeight: 'unset',
                    minHeight: '30px',
                    padding: 0,
                }
            }
        }
    },
    imgSize: {
        height: '170px !important',
        borderRadius: "10px",
        width: '100%',
        '@media screen and (max-width: 500px)': {
            height: '100px !important',
            borderRadius: "5px",
            width: '100%'
        }
    },
    imgBarBtn: {
        background: '#232955 !important',
        color: '#fff !important',
        padding: '2px 2px !important',
        marginRight: '10px !important',
        marginTop: "5px !important"
    }
}));
function Library({ context }) {
    const { role } = context
    const matches = useMediaQuery('(max-width:700px)');
    const classes = useStyles();
    const [openUpload, setOpenUpload] = useState(false);
    const [data, setData] = useState({});
    const [userList, setUserList] = useState([])
    const [userListFilter, setUserListFilter] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [id, setId] = React.useState(null);
    const [userIds, setUserIds] = useState([])
    const [page, setPage] = React.useState(1);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [loader, setLoader] = useState(true);
    const [userValue, setuserValue] = useState(null)
    const [userSearchValue, setUserSearchValue] = useState(null)
    const [libraryDetail, setLibraryDetail] = useState({ open: false, data: {} })
    const openMenu = Boolean(anchorEl);
    const handleClick = (event, id) => {
        setId(id)
        setAnchorEl(event?.currentTarget);
    };
    const handleUpload = () => {
        setOpenUpload(!openUpload);
    }
    useEffect(() => {
        if (role?.length) {
            if (role !== 'User') {
                loadUsersList();
            }else{
                loadLibraryList()
            }
        }
        document.title = "Library"
    }, [role])
    const loadUsersList = () => {
        Fetch('sports/user/user-listing/').then(res => {
            setLoader(false)
            if (res.status) {
                const val = res.data?.length && { label: res.data[0]?.name, id: res.data[0]?.id }
                setuserValue(val)
                loadLibraryList(page, val.id);
                setUserList(res.data)
                setUserListFilter(res.data)
            }
        })
    }
    const loadLibraryList = (page, id) => {
        Fetch(`sports/${role === 'User' ? 'user-library-list' : `library`}/${page ? '?page=' + page : ''}${role !== 'User' ? `&user_id=${id}` : ''}`).then(res => {
            setLoader(false)
            if (res.status) {
                setPage(page)
                setData(res.data)
                setUserIds(res.data.results[0]?.members)
            }
        })
    };
    const confirmdeleteLibrary = () => {
        setOpenDeleteConfirm(!openDeleteConfirm)
    }
    const searchFilter = (e) => {
        const { value } = e.target
        setUserSearchValue(value)
        setUserListFilter(userList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())));
    }
    const deleteLibrary = () => {
        Fetch(`sports/library/${id}/`, null, { method: 'delete' }).then(res => {
            if (res.status) {
                confirmdeleteLibrary()
                handleClick(null)
                loadLibraryList(page, userValue?.id)
                toast.success("Library Deleted Succesfully.", {
                    hideProgressBar: false,
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                let resErr = arrayString(res)
                toast.error(resErr.error, {
                    hideProgressBar: false,
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        })
    }
    const handleAssignChange = (checked, id) => {
        Fetch(`sports/unassign-library-image/${userValue?.id}/${id}/`, null, { method: 'patch' }).then(res => {
            if (res.status) {
                loadLibraryList(page, userValue?.id)
                toast.success(`Library ${checked ? 'Assigned' : 'Unassigned'} Succesfully.`, {
                    hideProgressBar: false,
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                let resErr = arrayString(res)
                toast.error(resErr.error, {
                    hideProgressBar: false,
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        })
    }
    const handleImg = (val) => {
        setLibraryDetail({
            open: !libraryDetail.open, data: val
        })
    }
    return (
        <AppLayout>
            <ToastContainer />
            <Container>
                <Grid container spacing={3} alignItems='center' className="library py-5">
                    <Grid item xs={6} sm={3}>
                        <h3> Library </h3>
                    </Grid>
                    {!matches ? null : role !== 'User' ? <Grid item xs={6} sm={9} className="butt"
                        style={{ display: "flex", justifyContent: "end" }}><ButtonUpload handleUpload={handleUpload} /></Grid>:null}
                    {role !== 'User' ? <Grid item xs={12} sm={9} className="butt"
                        style={{ display: "flex", justifyContent: "end" }}>
                        {!loader &&
                            <div className='flex align-center flex-wrap w-100 userselect'>
                                <InputLabel
                                    id="user"
                                    className="Input"
                                    style={{
                                        color: "rgba(12,11,69,255)",
                                        display: "flex",
                                        fontSize: "15px",
                                        marginBottom: matches ? 10 : 0,
                                        marginRight: '10px',
                                        fontWeight: "bold",
                                    }}
                                >
                                    User:
                                </InputLabel>
                                <Autocomplete
                                    disableClearable
                                    id="combo-box-demo"
                                    value={userValue}
                                    onChange={(event, newValue) => {
                                        setUserSearchValue('')
                                        setuserValue(newValue)
                                        loadLibraryList(page, newValue.id)
                                    }}
                                    options={userListFilter?.map(items => { return { label: items.name, id: items.id } })}
                                    sx={{ width: 300, backgroundColor: '#fff', marginRight: '20px' }}
                                    renderInput={(params) => <TextField variant='outlined' placeholder='Users' value={userSearchValue} {...params} onChange={searchFilter} />}
                                /></div>}
                        {matches ? null : <ButtonUpload handleUpload={handleUpload} />}
                    </Grid> : null}
                </Grid>
            </Container>
            <Container>
                <Paper elevation={3} style={{ marginBottom: "90px", padding: "30px", }}>
                    {
                        loader ?
                            <CircularProgress />
                            :
                            data?.results?.length ?
                                <ImageList gap={20} cols={matches ? 2 : 4}>
                                    {
                                        data?.results?.map((item, key) => (
                                            <ImageListItem key={key} onClick={(e) => { e.stopPropagation();handleImg(item)}} className={classes.imgList}>
                                                {
                                                    item.image_ext === 'png' || item.image_ext === 'jpeg' || item.image_ext === 'jpg' ?
                                                        <img
                                                            style={{ background: '#eaeaea', boxShadow: 'inset 0px -37px 50px 0px rgb(0, 0, 0)', }}
                                                            src={`${item.library_image ? item.library_image : '/no-image-icon.png'}?w=248&fit=crop&auto=format`}
                                                            srcSet={`${item.library_image ? item.library_image : '/no-image-icon.png'}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                            alt={item.title}
                                                            className={classes.imgSize}
                                                            loading="lazy"
                                                        />
                                                        :
                                                        <div className='position-relative'>
                                                            <video className={classes.imgSize} style={{ background: '#eaeaea', boxShadow: 'inset 0px -37px 50px 0px rgb(0, 0, 0)', }}>
                                                                <source src={item.library_image} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                            <PlayCircle className='videoIco' />
                                                        </div>

                                                }

                                                <ImageListItemBar
                                                    style={{ background: "none", zIndex: 1, textAlign: "center" }}
                                                    title={item.title}
                                                />
                                                {role === 'User' ? null : <><ImageListItemBar
                                                    style={{ background: "none", width: '50%', zIndex: 1, textAlign: "center" }}
                                                    position='top'
                                                    actionPosition='left'
                                                    actionIcon={
                                                        <Checkbox
                                                            // {...label}
                                                            checked={item?.is_checked}
                                                            // onChange={(e) => { e.stopPropagation(); handleAssignChange(e, item?.id) }}
                                                            onClickCapture={(e) => { e.stopPropagation(); handleAssignChange(!item?.is_checked, item?.id)}}
                                                            title='ldafa'
                                                            inputProps={{
                                                                'aria-label': 'Checkbox A',
                                                            }}
                                                            sx={{
                                                                color: '#fff !important',
                                                                '&.Mui-checked': {
                                                                    color: '#242b58',
                                                                },
                                                            }}
                                                        />
                                                    }
                                                /><ImageListItemBar
                                                        style={{ background: "none", width: '50%', left: 'unset', zIndex: 1, textAlign: "center" }}
                                                        position='top'
                                                        actionPosition='right'
                                                        actionIcon={
                                                            <IconButton
                                                                className={classes.imgBarBtn}
                                                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                                aria-label={`info about ${item.title}`}
                                                                id="basic-button"
                                                                aria-controls={openMenu ? 'basic-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={openMenu ? 'true' : undefined}
                                                                onClickCapture={(e) => { e.stopPropagation(); handleClick(e, item?.id) }}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        }
                                                    /></>}
                                            </ImageListItem>
                                        ))
                                    }
                                </ImageList>
                                :
                                <Box sx={{ textAlign: 'center' }}>
                                    {role !== 'User' ? <Typography style={{ paddingBottom: '10px' }}>No library available at the moment. Please click on the 'Upload' button to add your library.</Typography> : <Typography style={{ paddingBottom: '10px' }}>No library assigned at the moment.</Typography>}
                                    {role !== 'User' ? <Button
                                        variant="contained"
                                        type="button"
                                        onClick={handleUpload}
                                        style={{
                                            backgroundColor: "#232b58",
                                            textTransform: 'uppercase',
                                            color: "#fff",
                                            borderRadius: "25px",
                                            padding: "10px 30px",
                                            fontSize: "17px",
                                        }}
                                        className="btn-submit"
                                    >
                                        <CloudUploadIcon style={{
                                            marginRight: '10px'
                                        }} /> Upload
                                    </Button> : null}
                                </Box>
                    }
                    {data?.results?.length ? <Pagination data={data} pageSize={12} sendPageNumber={(val) => loadLibraryList(val, userValue?.id)} /> : null}
                </Paper>
            </Container>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                className={classes.menuMore}
                open={openMenu}
                onClose={() => handleClick(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={confirmdeleteLibrary}>Delete</MenuItem>
            </Menu>
            <Dialog open={openDeleteConfirm} onClose={() => { confirmdeleteLibrary(); handleClick(null) }}>
                <DialogTitle>Delete Library</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Library?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="Buttonss">
                    <Button onClick={() => { confirmdeleteLibrary(); handleClick(null) }}>No</Button>
                    <Button onClick={() => deleteLibrary()}>Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog className='fullImg-slide' open={libraryDetail.open} onClose={() => { handleImg({}) }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <DialogTitle className='headModel'>{libraryDetail?.data?.title}</DialogTitle>
                    <IconButton onClick={() => { handleImg({}) }}>
                        <Close style={{ color: "rgba(12,11,69,255)" }} />
                    </IconButton>
                </div>
                <DialogContent dividers>
                    {/* <List>
                        <ListItem><strong></strong></ListItem>
                    </List> */}
                    {
                        libraryDetail?.data?.image_ext === 'png' || libraryDetail?.data?.image_ext === 'jpeg' || libraryDetail?.data?.image_ext === 'jpg' ?
                            <img
                                style={{ background: '#eaeaea' }}
                                src={`${libraryDetail?.data?.library_image ? libraryDetail?.data?.library_image : '/no-image-icon.png'}?w=248&fit=crop&auto=format`}
                                srcSet={`${libraryDetail?.data?.library_image ? libraryDetail?.data?.library_image : '/no-image-icon.png'}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={libraryDetail?.data?.title}
                                loading="lazy"
                            />
                            :
                            <div className='position-relative'>
                                <video controls autoPlay style={{ background: '#eaeaea' }}>
                                    <source src={libraryDetail?.data?.library_image} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                    }
                </DialogContent>
            </Dialog>
            <UploadLibrary openUpload={openUpload} handleUpload={handleUpload} userList={userList} loadLibraryList={() => loadLibraryList(page, userValue?.id)} />
        </AppLayout>
    );
}
export default withContext(Library)
function ButtonUpload({ handleUpload }) {
    return <Button variant="contained"
        onClick={handleUpload}
        className="size"
        style={{
            float: "right", marginRight: "10px", backgroundColor: "white",
            color: "black", textTransform: "capitalize", fontWeight: "500",
            padding: '10px 15px',
            borderRadius: '4px'
        }}>
        <CloudUploadIcon style={{ paddingRight: '5px' }} />
        Upload
    </Button>
}