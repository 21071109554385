import React from "react";
import { IconButton, Button, TableRow, TableHead, TableCell, TableBody, Table, Container, Grid, Box, CircularProgress } from "@material-ui/core";
import { AddCircleOutlineOutlined, Delete, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./sportscenterowner.css";
import { DialogActions, Dialog, DialogContent, DialogContentText, DialogTitle, TableContainer } from "@mui/material";
import AppLayout from "../../layout/appLayout";
import Pagination from "../../component/Page/Pagination";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { arrayString, paginationSize } from "../../common/utilits";
import TypeSearch from "../../component/search";
export default function Sportscenterowner() {
  const [data, setData] = useState({});
  const [searchTerm, setsearchTerm] = useState("");
  const [setid, SetsetId] = useState();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState(1)
  const [loader, setLoader] = useState(true)

  const handleClickOpen = (id) => {
    setOpen(true);
    SetsetId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.title = "Centre Owner Management";
    loadUsers();
  }, []);

  const deleteUser = () => {
    Fetch(`sports/owner/${setid}/`, null, { method: 'delete' }).then(res => {
      if (res?.status) {
        toast.success("Owner Deleted Successfully.", {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOpen(false);
        loadUsers(page, searchTerm);
      } else {
        let resErr = arrayString(res)
        toast.error(resErr.error, {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  };

  const loadUsers = (page, search = '') => {
    Fetch(`sports/owner/${page ? '?page=' + page : ''}${search ? '&search=' + search : ''}`).then(res => {
      setLoader(false)
      if (res.status) {
        setsearchTerm(search)
        setData(res.data);
        setPage(page ? page : 1)
      }
    })
  };
  return (
    <AppLayout>
      <ToastContainer />
      <Container maxWidth="lg">
        <Grid container spacing={3} alignItems='center' className="owners py-5">
          <Grid
            item
            xs={12}
            sm={6}
          >
            <h3
              className="topheading
            "
            >
              {" "}
              Centre Owners
            </h3>
          </Grid>
          <Grid
            className="buttons headSearch"
            item
            xs={12}
            sm={6}
          >
            <TypeSearch handleChange={(val) => loadUsers(1, val)} />
            <Link to="/sports/add" style={{ textDecoration: "none" }}>
              <Button
                className="add-btn"
                variant="contained"
              >
                <AddCircleOutlineOutlined style={{ marginRight: "6px" }} />
                Add Centre Owner
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <div className="WidgetLg" style={{ marginBottom: "85px" }}>
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"> S.No.</TableCell>
                  <TableCell align="left">Owner Name </TableCell>
                  <TableCell align="left">Email </TableCell>
                  <TableCell align="left"> Location </TableCell>
                  <TableCell align="left"> Contact No.</TableCell>
                  <TableCell align="center"> Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                loader ?
                <TableRow>
                  <TableCell colSpan={6} align="center"><CircularProgress /></TableCell>
                </TableRow>
                :
                data?.results?.length ?
                  data?.results?.map((admin, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell align="center">{(page - 1) * paginationSize + i + 1}</TableCell>
                        <TableCell align="left">
                          {admin?.name}
                        </TableCell>
                        <TableCell align="left">
                          {admin?.email}
                        </TableCell>
                        <TableCell align="left">{admin?.location}</TableCell>
                        <TableCell align="left">
                          {admin?.phone_no}
                        </TableCell>
                        <TableCell align="center">
                          <Box className="flex align-center">
                            <Link to={`/sports/${admin?.id}`}>
                              <IconButton
                                variant="contained"
                                title="Edit"
                                type="submit"
                                style={{
                                  padding: "0",
                                  boxShadow: "none",
                                  border: "none",
                                  background: "none",
                                  minWidth: "0px",
                                }}
                              >
                                <Edit
                                  style={{ color: "#17a248", margin: "-5px" }}
                                />
                              </IconButton>
                            </Link>
                            <IconButton
                              title="Delete"
                              onClick={() => handleClickOpen(admin?.id)}
                              style={{
                                padding: "0",
                                boxShadow: "none",
                                border: "none",
                                background: "none",
                                minWidth: "0px",
                              }}
                            >
                              <Delete style={{ color: "red", margin: "8px" }} />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                  :
                  <TableRow>
                    <TableCell colSpan={5} align="center">No Data Found</TableCell>
                  </TableRow>
                }
              </TableBody>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Delete Owner</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this Owner?
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="Buttonss">
                  <Button onClick={handleClose}>No</Button>
                  <Button onClick={() => deleteUser()}>Yes</Button>
                </DialogActions>
              </Dialog>
            </Table>
          </TableContainer>
          {data?.results?.length ? <Pagination data={data} sendPageNumber={loadUsers} /> : null}
        </div>
      </Container>
    </AppLayout>
  );
}


