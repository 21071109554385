export const menuList = [
    {
        name: 'Centre Owner Management',
        url: '/centreowner',
        icon:'/icon/menu/sports_center.png',
        role: ["Admin"]
    },
    {
        name: 'Coach Management',
        url: '/coachmanagement',
        icon:'/icon/menu/coaches.png',
        role: ["Admin"]
    },
    {
        name: 'User Management',
        role: ["Admin"],
        icon:'/icon/menu/user_management.png',
        url: '/usermanagement',
    },
    {
        name: 'Category Management',
        icon:'/icon/menu/categories.png',
        role: [""],
        url: '/categorymanagement',
    },
    // {
    //     name: 'Settings',
    //     role: ["Admin"],
    //     icon:'/icon/menu/settings.png',
    //     url: '/settings',
    // },
    {
        name: 'Centre',
        icon:'/icon/menu/sports_center.png',
        role: ["Owner", "User","Coach"],
        url: '/centre',
    },
    {
        name: 'Coaches',
        icon:'/icon/menu/coaches.png',
        role: ["Owner"],
        url: '/coachmanagement',
    },
    {
        name: 'Schedule',
        icon:'/icon/menu/schedule.png',
        role: ["Owner"],
        url: '/schedule',
    },
    {
        name: 'Schedule',
        icon:'/icon/menu/schedule.png',
        role: ["Coach"],
        url: '/schedules',
    },
    {
        name: 'Transactions',
        icon:'/icon/menu/transaction.png',
        role: ["Owner"],
        url: '/transactions',
    },
    {
        name: 'Categories',
        icon:'/icon/menu/categories.png',
        role: ["Owner"],
        url: '/categorymanagement',
    },
    {
        name: 'Subscription Plans',
        icon:'/icon/menu/subscription.png',
        role: ["Owner"],
        url: '/subscription',
    },
    {
        name: 'Library',
        icon:'/icon/menu/library.png',
        role: ["User", "Coach"],
        url: '/library',
    },
    {
        name: 'Plan',
        icon:'/icon/menu/diet_plan.png',
        role: ["User"],
        url: '/dietplan',
    },
    {
        name: 'Plan',
        icon:'/icon/menu/diet_plan.png',
        role: ["Coach"],
        url: '/plan',
    },
    {
        name: 'Subscription',
        icon:'/icon/menu/subscription.png',
        role: ["User"],
        url: '/subscription',
    },
    {
        name: 'Chat',
        icon:'/icon/menu/chat.png',
        role: ["Owner", "Coach"],
        url: '/chat',
    },
]
export const dietPlanDays = [
    {
        name:'Monday',
        id:1
    },
    {
        name:'Tuesday',
        id:2
    },
    {
        name:'Wednesday',
        id:3
    },
    {
        name:'Thursday',
        id:4
    },
    {
        name:'Friday',
        id:5
    },
    {
        name:'Saturday',
        id:6
    },
    {
        name:'Sunday',
        id:7
    }
]