import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Paper, Grid, TextField, InputLabel, Container, MenuItem, Select, ListItem, List, FormHelperText } from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import AppLayout from "../../layout/appLayout";
import CircularProgress from "@mui/material/CircularProgress";
import { Clear, Upload } from "@mui/icons-material";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { arrayString,onKeyPress } from "../../common/utilits";
import Back from "../../component/backIco";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  filename: {
    fontSize: '12px',
    padding: 0,
    paddingRight: '10px',
    '& svg': {
      width: '20px',
      height: '20px'
    },
    '& button': {
      padding: 0,
      paddingLeft: '10px',
      minWidth: 'auto',
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
let validateError = {
  location: yup
    .string()
    .required("Location is required."),
  phone_no: yup
    .string()
    .matches(phoneRegExp, "Phone number must contains only digits.")
    .min(10, "Phone number should not be less than 10 digits.")
    .max(10, "Phone number should not be more than 10 digits.")
    .required("Phone number is required."),
  owner: yup
    .string()
    .required("owner is required."),
  center_name: yup
    .string()
    .required("Center is required."),
  // price: yup.string().required("Price is required."),
  service: yup.string().required("Specialization is required."),
}

const SportsCenterSetting = () => {
  let navigate = useNavigate();
  const classes = useStyles();
  const param = useParams()
  const validationSchema = yup.object().shape(validateError);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    location: "",
    owner: "",
    phone_no: "",
    center_name: "",
    // price: '',
    service: "",
  });
  useEffect(() => {
    loadUser()
  }, [param])
  const loadUser = () => {
    Fetch(`sports/coach-assign/${param.id}/`).then(res => {
      if (res.status) {
        const { data } = res
        setState({
          location: data.location,
          owner: data.owner_name,
          phone_no: data.phone_no,
          center_name: data.center_name,
          // price: data.price || '',
          service: data.service || '',
        })
      }
    })
  }
  const onSubmit = (e) => {
    if (formik.isValid) {
      setIsLoading(true);
      Fetch(`sports/coach-assign/${param.id === 'add' ? '' : param.id + '/'}`, formik.values, { method: param.id === 'add' ? 'post' : 'patch', inFormData: true }).then(res => {
        setIsLoading(false);
        if (res.status) {
          toast.success(`Centre Setting ${param.id === 'add' ? 'Added' : 'Updated'} Successfully.`, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/centre/");
          }, 3000)
        } else {
          let resErr = arrayString(res)
          toast.error(resErr.error, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
    }
  };
  const formik = useFormik({
    initialValues: state,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <AppLayout style={{ marginBottom: "50px" }}>
      <ToastContainer />
      <Container>
        <h3 className="flex align-center" style={{ padding: "10px 10px 10px 0px" }}><Back/>Centre Setting</h3>
        <Paper elevation={3}>
          <div
            className={classes.root}
            style={{ padding: "20px", marginBottom: "80px" }}
          >
            <form method="POST" noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    id="center_name"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Centre Name
                  </InputLabel>
                  <TextField
                    id="sport_center"
                    inputProps={{ maxLength: 50 }}
                    disabled
                    error={Boolean(
                      formik.touched.center_name &&
                      formik.errors.center_name
                    )}
                    margin="normal"
                    fullWidth
                    helperText={
                      formik.touched.center_name &&
                      formik.errors.center_name
                    }
                    name="center_name"
                    value={formik.values.center_name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Owner
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(formik.touched.owner && formik.errors.owner)}
                    disabled
                    fullWidth
                    helperText={formik.touched.owner && formik.errors.owner}
                    margin="normal"
                    // disabled={param.id !== 'add'}
                    name="owner"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="owner"
                    autoComplete="off"
                    value={formik.values.owner}
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    id="speciallsation"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Specialization
                  </InputLabel>
                  <Select
                    onBlur={formik.handleBlur}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => { formik.handleChange(e) }}
                    value={formik.values.service}
                    required
                    style={{ marginTop: "13px" }}
                    name="service"
                    helperText={
                      formik.touched.service &&
                      formik.errors.service
                    }
                    error={Boolean(
                      formik.touched.service &&
                      formik.errors.service
                    )}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>
                        --- Select Specialization ---
                      </em>
                    </MenuItem>
                    <MenuItem value="Cardio">Cardio</MenuItem>
                    <MenuItem value="Strength">Strength</MenuItem>
                  </Select>
                  {formik.touched.service && formik.errors.service && (
                    <FormHelperText className="Mui-error">
                      {formik.errors.service}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Location
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                      formik.touched.location && formik.errors.location
                    )}
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                    margin="normal"
                    required
                    disabled
                    fullWidth
                    onBlur={formik.handleBlur}
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    name="location"
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                {/* <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Price {`(INR/Month)`}
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 5 }}
                    error={Boolean(
                      formik.touched.price && formik.errors.price
                    )}
                    helperText={
                      formik.touched.price && formik.errors.price
                    }
                    margin="normal"
                    onKeyPress={onKeyPress}
                    required
                    fullWidth
                    onBlur={formik.handleBlur}
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    name="price"
                    variant="outlined"
                    type="text"
                  />
                </Grid> */}
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Phone No.
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 10 }}
                    error={Boolean(
                      formik.touched.phone_no && formik.errors.phone_no
                    )}
                    margin="normal"
                    required
                    fullWidth
                    helperText={
                      formik.touched.phone_no && formik.errors.phone_no
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.phone_no}
                    disabled
                    onChange={formik.handleChange}
                    autoComplete="off"
                    name="phone_no"
                    variant="outlined"
                    type="tel"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ textAlign: "center", marginTop: "100px" }}>
                    <Button
                      variant="contained"
                      // disabled={isSubmitting}
                      type="submit"
                      style={{
                        backgroundColor: "#232b58",
                        textTransform: 'uppercase',
                        color: "#fff",
                        borderRadius: "25px",
                        width: "290Px",
                        padding: "13px",
                        fontSize: "17px",
                      }}
                      // onClick={(e) => onSubmit(e)}
                      className="btn-submit"
                    >
                      {isLoading ? <CircularProgress Shrink /> : 'Save'}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </AppLayout>
  );
};
export default SportsCenterSetting;