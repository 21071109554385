import React, { useEffect, useState } from "react";
import { TableBody, TableRow, TableHead, TableCell, Grid } from "@material-ui/core";
import { Container } from "@material-ui/core";
import "../Coachman/dataman.css";
import { Paper, TableContainer, Table } from "@mui/material";
import Pagination from "../../component/Page/Pagination";
import Fetch from "../../common/fetch";
import AppLayout from '../../layout/appLayout';
import TypeSearch from "../../component/search";

export default function Schedule() {
    const [data, setData] = useState({
        count:1,
        results:[{
            user_name:'Girish',
            coach:'Manthan',
            division_center:'YMCA Cricket Academy',
            timings:'09:00 AM',
        },
        {
            user_name:'Shubham',
            coach:'Sagar',
            division_center:'YMCA Cricket Academy',
            timings:'12:00 PM',
        },{
            user_name:'Ekta',
            coach:'Siraj',
            division_center:'YMCA Cricket Academy',
            timings:'02:00 PM',
        }]
    });
    const [searchTerm, setsearchTerm] = useState("");
    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = (page) => {

    };
    return (
        <AppLayout>
            <Container maxWidth="lg">
                <Grid container spacing={3} alignItems="center" className="coachmg py-5">
                    <Grid
                        item
                        xs={12}
                        sm={6}
                    >
                        <h3>Schedule</h3>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        className='headSearch'
                    >
                        <TypeSearch />
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <div className="WidgetLg" style={{ marginBottom: "85px" }}>
                    <TableContainer>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.No.</TableCell>
                                    <TableCell align="left">User Name</TableCell>
                                    <TableCell align="left">Coach</TableCell>
                                    <TableCell align="left">Centre</TableCell>
                                    <TableCell align="left" width={'10%'}>Timings</TableCell>
                                    <TableCell align="center">Package</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data?.results?.length ?
                                    data?.results?.filter((val) => {
                                        if (searchTerm === "") {
                                            return val;
                                        } else if (
                                            val.location
                                                .toLowerCase()
                                                .includes(searchTerm.toLowerCase())
                                        ) {
                                            return val;
                                        }
                                    })
                                        .map((admin, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell align="center">{i + 1}</TableCell>
                                                    <TableCell align="left">{admin?.user_name}</TableCell>
                                                    <TableCell align="left">{admin.coach}</TableCell>
                                                    <TableCell align="left">
                                                        {admin?.division_center}
                                                    </TableCell>
                                                    <TableCell align="left" width={'10%'}>{admin?.timings}</TableCell>
                                                    <TableCell align="center">
                                                        12
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">No Data Found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {data?.results?.length ? <Pagination data={data} sendPageNumber={loadUsers} /> : null}
                </div>
            </Container>
        </AppLayout>
    );
}
