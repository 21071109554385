import { withContext } from "../../context/appContext";
import AppLayout from "../../layout/appLayout";
import "../Sportscenterowner/sportscenterowner.css";
import CoachSportscenter from "./coachSportCenter";
import OwnerSportscenter from "./ownerSportCenter";
import UserSportscenter from "./userSportCenter";
function Sportscenter({ context }) {
  const { role } = context
  return (
    <AppLayout>
      {
        role === "User" ? <UserSportscenter/> :role === "Coach" ? <CoachSportscenter /> : <OwnerSportscenter />
      }
    </AppLayout>
  );
}
export default withContext(Sportscenter);