import Auth from './auth';
import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_ENDPOINT
const commonParams = {}
export default function Fetch(endPoint, params = {}, option = {}) {
  const method= option?.method ?? 'get'
  const inFormData = option?.inFormData ?? false // formType === true
  const isToken = option?.isToken ?? true;
  const url = option?.url
  const baseURL = option?.baseURL ?? apiUrl
  let parameters = Array.isArray(params) ? params: {
    ...commonParams,
    ...params,
  }
  const FetchHeader = (token) => {
    const headers = {
      'Content-Type': inFormData ? 'multipart/form-data' : 'application/json',
      Authorization: `Bearer ${token || ''}`,
    }
    if (!token) {
      delete headers['Authorization']
    }
    return {
      headers,
    }
  }
  const convertToForm = () => {
    let formData = new FormData()
    for (let name in parameters) {
      if (Array.isArray(parameters[name])) {
        for (var i = 0; i < parameters[name].length; i++) {
          formData.append(`${name}`, parameters[name][i]);
        }
      } else {
        formData.append(name, parameters[name])
      }
    }
    return formData
  }
  // const optionList:any = {
  //   method:method,
  //   url:url ? url : baseURL + endPoint,
  //   inFormData
  //   ? convertToForm()
  //   : Object.keys(parameters)?.length
  //     ? parameters
  //     : FetchHeader('')
  // }
  const fetch = (token) => {
    return axios[method](
      url ? url : baseURL + endPoint,
      inFormData
        ? convertToForm()
        : Object.keys(parameters)?.length || Array.isArray(parameters)
          ? parameters
          : FetchHeader(token),
      FetchHeader(token),
    )
      .then((d) => {
        const dataParse = { data: d.data, status: true }
        return dataParse
      })
      .catch((err) => {        
        if(err?.response?.status === 500){
          return { err:['Something Went Wrong'], status: false }
        }else{
          return { ...err?.response?.data, status: false }
        }
      })
  }
  if (isToken === false) {
    return fetch(isToken);
  }
  return Auth.getAsyncToken().then((res) => fetch(res.token))
}