import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/login";
import SignUp from "./pages/sign-up";
import Forgetpassword from "./pages/forget-password";
import Changepassword from "./pages/forget-password/change-password";

import { PrivateRoutes, GuestRoutes } from "./component/useAuth/PrivateRoute";
import Sportscenterowner from "./pages/Sportscenterowner/Sportscenterowner";
import Coachmanagement from "./pages/Coachman/Coachmanagement";
import Usermanagement from "./pages/Userman/Usermanagement";
import Categorymanagement from "./pages/Categoryman/Categorymanagement";
import Setting from "./pages/Setting/Setting";
import Addcategory from "./pages/Categoryman/Addcategory";
import AddCoach from "./pages/Coachman/Addcoach";
import Addnew from "./pages/Sportscenterowner/Addnew";
import AddUser from "./pages/Userman/Adduser";
import Schedule from "./pages/schedule";
import SubscriptionPlan from "./pages/subscription-plan";
import Transactions from "./pages/transactions";
import Chat from "./pages/chat";
import Sportscenter from "./pages/sportsCenter/Sportscenterowner";
import AddNewSportsCenter from "./pages/sportsCenter/Addnew";
import Subscription from "./pages/subscription-list";
import DietPlan from "./pages/Dietplan";
import Library from "./pages/Library";
import ScheduleCoach from "./pages/schedule-coach";
import DietCoach from "./pages/DietCoach";
import AddDietCoach from "./pages/DietCoach/add";
import SportsCenterSetting from "./pages/sportsCenter/AddnewCoach";
import SportsCenterDetail from "./pages/sportsCenter/userDetail";
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route element={<GuestRoutes />}>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/:id" element={<Login />} />
            <Route exact path="/forgetpassword" element={<Forgetpassword />} />
            <Route
              exact
              path="/reset-password/:id"
              element={<Changepassword />}
            />
            <Route exact path="/signup" element={<SignUp />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path={`/centreowner`} element={<Sportscenterowner />} />
            <Route path={`/centre`} element={<Sportscenter />} />
            <Route path={`/sportscentersetting/:id`} element={<SportsCenterSetting />} />
            <Route path={`/centre/:id`} element={<AddNewSportsCenter />} />
            <Route path={`/centre/detail/:id`} element={<SportsCenterDetail />} />
            <Route path={`/coachmanagement`} element={<Coachmanagement />} />
            <Route path={`/usermanagement`} element={<Usermanagement />} />
            <Route path={`/categorymanagement`} element={<Categorymanagement />} />
            <Route path={`/schedule`} element={<Schedule />} />
            <Route path={`/schedules`} element={<ScheduleCoach />} />
            <Route path={`/plan`} element={<DietCoach />} />
            <Route path={`/plan/:id/:days`} element={<AddDietCoach />} />
            <Route path={`/subscription`} element={<SubscriptionPlan />} />
            <Route path={`/subscription-plans`} element={<Subscription />} />
            <Route path={`/dietplan`} element={<DietPlan />} />
            <Route path={`/library`} element={<Library />} />
            <Route path={`/transactions`} element={<Transactions />} />
            <Route path={`/settings`} element={<Setting />} />
            <Route path={`/chat`} element={<Chat />} />
            <Route path={`/category/:id`} element={<Addcategory />} />
            <Route path={`/coach/:id`} element={<AddCoach />} />
            <Route path={`/sports/:id`} element={<Addnew />} />
            <Route path={`/user/:id`} element={<AddUser />} />
            {/* <Route path={`/editcoach/:id`} element={<Editcoach />} />
            <Route path={`/editcategory/:id`} element={<Editcategory />} />
            <Route path={`/edituser/:id`} element={<EditUser />} />
            <Route path={`/editnew/:id`} element={<Editnew />} /> */}
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
