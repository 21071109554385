import { React } from "react";
import { Button, CssBaseline, TextField, Grid, Typography, Container, InputLabel, useMediaQuery } from "@material-ui/core";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { Email, VpnKeyOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import "./Login.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";
import Fetch from "../../common/fetch";
import { arrayString } from "../../common/utilits";
import { ToastContainer, toast } from 'react-toastify';
import { withContext } from "../../context/appContext";
const emailRegx = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  labelform: {
    color: "rgba(12,11,69,255)",
    padding: "5px",
    paddingLeft: 0,
    display: "flex",
    fontSize: "15px",
    letterSpacing: "2px",
    fontWeight: "600",
    alignItems: 'center',
    marginTop: "20px",
    '& .icon': {
      fontSize: '15px !important'
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginTop: "30px",
      '& .icon': {
        fontSize: '14px !important'
      }
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    marginTop: "100px",
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      margin: "80px 0 0",
    },
  },
  btnAuth: {
    backgroundColor: "rgba(12,11,69,255) !important",
    color: "white",
    borderRadius: " 28px 28px",
    padding: "10px 81px !important",
    fontSize: "18px",
    marginTop: "60px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      fontSize: "16px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  "@media (min-width: 360px)": {
    buttonContainer: {
      marginTop: "800px",
    },
  },
  "@media (min-width: 768px)": {
    buttonContainer: {
      marginTop: "800px",
    },
  },
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .matches(emailRegx, "Invalid Email ID...")
    .required("Email is required."),
  password: yup
    .string()
    .required("Password is required."),
});

function SignInSide({ context }) {
  const matches = useMediaQuery('(max-width:959px)');
  const navigate = useNavigate();
  const params = useParams()
  const { state } = useLocation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [activeUser, setactiveUser] = useState(true);
  const [stateForm, setStateForm] = useState({
    email: '',
    password: ''
  })
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  useEffect(() => {
    document.title = "Sign In";
    if (params?.id) {
      verifyUser(params?.id)
    }
  }, [params]);
  const verifyUser = (id) => {
    Fetch(`users/activate-user/${id}/`).then(res => {
      if (res?.status) {
        toast.success('Your account verified successfully.', {
          hideProgressBar: false,
          toastId: 'success1',
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        let resErr = arrayString(res);
        setIsLoading(false);
        toast.error(resErr.error, {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  }
  const onChange = (e) => {
    const { name, value } = e.taget
    setStateForm({
      ...stateForm,
      [name]: value
    })
  }
  const onSubmit = (e) => {
    if (formik.isValid) {
      setIsLoading(true);
      Fetch("users/login/", formik.values, { method: 'post' }).then(res => {
        setIsLoading(false);
        if (res.status) {
          context.updateAuthenticated(res.data);
          const { token, role, name } = res.data
          const { access, refresh } = token;
          let url = role !== 'Admin' ? '/centre' : '/centreowner'
          localStorage.refresh_token = refresh;
          localStorage.setItem("token", access);
          localStorage.setItem("role", role);
          localStorage.setItem("name", name);
          setTimeout(() => {
            setIsLoading(false);
            if (matches) {
              window.location.href = url
            } else {
              navigate(state?.path || url);
            }
          }, 2000)
        } else {
          if (res.active === false) {
            setactiveUser(res.active)
          }
          let resErr = arrayString(res);
          setIsLoading(false);
          toast.error(resErr.error, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
    }
  };
  const handleResend = () => {
    Fetch('users/resend-activation-mail', { email: formik.values.email }, { method: 'post' }).then(res => {
      if (res.status) {
        toast.success(`Verification email resent to your email address`, {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        let resErr = arrayString(res);
        setIsLoading(false);
        toast.error(resErr.error, {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  }
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <Grid container component="main" className={classes.root}>
          <ToastContainer />
      <CssBaseline />
      <Grid md={12}>
        <div className="bgimg">
          <Container maxWidth="false">
            <div className="main">
              <Grid
                container
                spacing={2}
                className="firstsignin"
              >
                <Grid item md={6} lg={6}>
                  <div className="sports-app">
                    <p style={{ fontSize: "20px" }}>WELCOME TO</p>
                    <Typography component="h1" variant="h5" className="Sig">
                    Centre <br></br>Management
                      <hr
                        style={{
                          width: "36%",
                          textAlign: "left",
                          marginLeft: "0",
                        }}
                      />
                    </Typography>
                  </div>
                </Grid>
                <Grid item md={6} lg={6}>
                  <div className={classes.paper}>
                    <h2 className="signupheading">
                      Sign In
                    </h2>
                    <p className="p">Sign in with your Email & Password</p>
                    {!activeUser ? <div className={`alert alert-danger resendIt`}>You account has not been verified yet. Please verify that from the previous email or if you haven't received email, click <Button onClick={handleResend} className='btn-back p0' type='button'>resend</Button> to request a new verification email.</div> : null}
                    <form
                      className={classes.form}
                      method="POST"
                      noValidate
                      onSubmit={formik.handleSubmit}
                    >
                      <InputLabel
                        className={`InputLabel ${classes.labelform}`}
                      >
                        <Email className="icon" style={{ fontSize: "18px" }} />
                        EMAIL
                      </InputLabel>
                      <TextField
                        inputProps={{ maxLength: 30 }}
                        error={Boolean(
                          formik.touched.email && formik.errors.email
                        )}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        margin="normal"
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyUp={onChange}
                        type="email"
                        value={formik.values.email}
                      />

                      <InputLabel
                        className={`InputLabel ${classes.labelform}`}
                      >
                        <VpnKeyOutlined className="icon" />
                        PASSWORD
                      </InputLabel>
                      <TextField
                        inputProps={{ maxLength: 30 }}
                        error={Boolean(
                          formik.touched.password && formik.errors.password
                        )}
                        fullWidth
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        margin="normal"
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyUp={onChange}
                        type={showPassword ? "text" : "password"}
                        style={{ color: "#fff" }}
                        value={formik.values.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={{ textAlign: "right", fontSize: "16px" }}
                        >
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "rgb(85,26,139)",
                            }}
                            to="/forgetpassword"
                          >
                            Forget Password?
                          </Link>
                        </Grid>
                      </Grid>

                      <Grid container justifyContent="center">

                        <Button
                          className={`btn-submit ${classes.btnAuth}`}
                          variant="outlined"
                          type="submit"
                          size="medium"
                          color="primary"
                        >
                          {isLoading === true ? (
                            <CircularProgress Shrink />
                          ) : (
                            "SIGN IN"
                          )}
                        </Button>
                      </Grid>
                      <Grid container className="link">
                        <Grid
                          item
                          container
                          justify="center"
                          style={{ display: "flex" }}
                        >
                          <p style={{ marginRight: "6px", marginTop: "0px" }}>
                            Don't have an account?
                          </p>
                          <Link
                            style={{
                              textDecoration: "none",
                              fontWeight: "bold",
                            }}
                            to="/signup"
                            variant="body2"
                          >
                            {"  Sign Up here!"}
                          </Link>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </Grid>
    </Grid>
  );
}
export default withContext(SignInSide)