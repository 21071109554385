import React, { useEffect, useState } from "react";
import { TableBody, TableRow, TableHead, TableCell, Grid, IconButton, Button, CircularProgress } from "@material-ui/core";
import { Container } from "@material-ui/core";
import "../Coachman/dataman.css";
import { Paper, TableContainer, Table } from "@mui/material";
import Pagination from "../../component/Page/Pagination";
import Fetch from "../../common/fetch";
import AppLayout from '../../layout/appLayout';
import { Delete, Edit } from "@material-ui/icons";
import TypeSearch from "../../component/search";

export default function SubscriptionPlan() {
    const [data, setData] = useState({
        count:1,
        results:[{
            category:'Ice Skatting',
            amount:'200 INR/Monthly',
            division_center:'YMCA Cricket Academy',
            duration:'09:00',
        },
        {
            category:'Basketball',
            amount:'1000 INR/Monthly',
            division_center:'YMCA Cricket Academy',
            duration:'03:00',
        },
        {
            category:'Baseball',
            amount:'2000 INR/Monthly',
            division_center:'YMCA Cricket Academy',
            duration:'02:00',
        }]
    });
    const [loader, setLoader] = useState(true)
    const [searchTerm, setsearchTerm] = useState("");
    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = (page) => {
        setLoader(false)
    };
    return (
        <AppLayout>
            <Container maxWidth="lg">
                <Grid container spacing={3} alignItems='center' className="coachmg py-5">
                    <Grid
                        item
                        sm={6}
                        xs={12}
                    >
                        <h3>Subscription Plan</h3>
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <TypeSearch />
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <div className="WidgetLg" style={{ marginBottom: "85px" }}>
                    <TableContainer>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.No.</TableCell>
                                    <TableCell align="left">Centre</TableCell>
                                    <TableCell align="left">Category</TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                    <TableCell align="left" width={'10%'}>Duration</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    loader ?
                                        <TableRow>
                                            <TableCell colSpan={6} align="center"><CircularProgress /></TableCell>
                                        </TableRow>
                                        :
                                        data?.results?.length ?
                                            data?.results?.filter((val) => {
                                                if (searchTerm === "") {
                                                    return val;
                                                } else if (
                                                    val.location
                                                        .toLowerCase()
                                                        .includes(searchTerm.toLowerCase())
                                                ) {
                                                    return val;
                                                }
                                            })
                                                .map((admin, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            <TableCell align="center">{i + 1}</TableCell>
                                                            <TableCell align="left">{admin?.division_center}</TableCell>
                                                            <TableCell align="left">{admin.category}</TableCell>
                                                            <TableCell align="left">
                                                                {admin?.amount}
                                                            </TableCell>
                                                            <TableCell align="left" width={'10%'}>{admin?.duration}</TableCell>
                                                            <TableCell align="center">
                                                                {/* <Link to={`/coach/${admin.id}`}> */}
                                                                {/* <IconButton
                                                                    variant="contained"
                                                                    type="submit"
                                                                    style={{
                                                                        padding: "0",
                                                                        boxShadow: "none",
                                                                        border: "none",
                                                                        background: "none",
                                                                        minWidth: "0px",
                                                                    }}
                                                                >
                                                                    <Edit style={{ color: "#17a248" }} />
                                                                </IconButton>
                                                                <Button
                                                                    onClick={() => { }}
                                                                    style={{
                                                                        padding: "0",
                                                                        boxShadow: "none",
                                                                        border: "none",
                                                                        background: "none",
                                                                        minWidth: "0px",
                                                                    }}
                                                                >
                                                                    <Delete style={{ color: "red", margin: "7px" }} />
                                                                </Button> */}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            :
                                            <TableRow>
                                                <TableCell colSpan={6} align="center">No Data Found</TableCell>
                                            </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {data?.results?.length ? <Pagination data={data} sendPageNumber={loadUsers} /> : null}
                </div>
            </Container>
        </AppLayout>
    );
}
