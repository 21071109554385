import React, { useEffect, useState } from "react";
import { TableBody, TableRow, TableHead, TableCell, Grid } from "@material-ui/core";
import { Container } from "@material-ui/core";
import "../Coachman/dataman.css";
import { TableContainer, Table } from "@mui/material";
import Pagination from "../../component/Page/Pagination";
import Fetch from "../../common/fetch";
import AppLayout from '../../layout/appLayout';
import TypeSearch from "../../component/search";

export default function Transactions() {
    const [data, setData] = useState({
        count:1,
        results:[{
            user_name:'Girish',
            role:'User',
            division_center:'YMCA Cricket Academy',
            date:'29 Feb 2024',
        },
        {
            user_name:'Manthan',
            role:'Coach',
            division_center:'YMCA Cricket Academy',
            date:'29 Feb 2024',
        },
        {
            user_name:'Ekta',
            role:'Owner',
            division_center:'YMCA Cricket Academy',
            date:'29 Feb 2024',
        }]
    });
    const [searchTerm, setsearchTerm] = useState("");
    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = (page) => {

    };
    return (
        <AppLayout>
            <Container maxWidth="lg">
                <Grid container spacing={3} alignItems='center' className="coachmg py-5">
                    <Grid
                        item
                        sm={6}
                        xs={12}
                    >
                        <h3>Transactions</h3>
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <TypeSearch />
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <div className="WidgetLg" style={{ marginBottom: "85px" }}>
                    <TableContainer>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.No.</TableCell>
                                    <TableCell align="left">User Name</TableCell>
                                    <TableCell align="left">Role</TableCell>
                                    <TableCell align="left">Centre</TableCell>
                                    <TableCell align="left" width={'10%'}>Date</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data?.results?.length ?
                                    data?.results?.filter((val) => {
                                        if (searchTerm === "") {
                                            return val;
                                        } else if (
                                            val.location
                                                .toLowerCase()
                                                .includes(searchTerm.toLowerCase())
                                        ) {
                                            return val;
                                        }
                                    })
                                        .map((admin, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell align="center">{i + 1}</TableCell>
                                                    <TableCell align="left">{admin?.user_name}</TableCell>
                                                    <TableCell align="left">{admin.role}</TableCell>
                                                    <TableCell align="left">
                                                        {admin?.division_center}
                                                    </TableCell>
                                                    <TableCell align="left" width={'10%'}>{admin?.date}</TableCell>
                                                    <TableCell align="center">
                                                        {/* asdad */}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">No Data Found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {data?.results?.length ? <Pagination data={data} sendPageNumber={loadUsers} /> : null}
                </div>
            </Container>
        </AppLayout>
    );
}
