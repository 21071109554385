import React from "react";
import { IconButton, Button, TableRow, TableHead, TableCell, TableBody, Table, Container, Grid, CircularProgress } from "@material-ui/core";
import { AddCircleOutlineOutlined, Delete, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { DialogActions, Dialog, DialogContent, DialogContentText, DialogTitle, TableContainer } from "@mui/material";
import Pagination from "../../component/Page/Pagination";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { arrayString, paginationSize } from "../../common/utilits";
import TypeSearch from "../../component/search";
import moment from "moment";
export default function OwnerSportscenter() {
  const [data, setData] = useState({});
  const [searchTerm, setsearchTerm] = useState("");
  const [page, setPage] = useState(1)
  const [setid, SetsetId] = useState();
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const handleClickOpen = (id) => {
    setOpen(true);
    SetsetId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.title = "Centre";
    loadUsers();
  }, []);

  const deleteUser = () => {
    Fetch(`sports/sports-center/${setid}/`, null, { method: 'delete' }).then(res => {
      if (res?.status) {
        toast.success("Centre Owner Deleted Successfully.", {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOpen(false);
        loadUsers(page);
      } else {
        let resErr = arrayString(res)
        toast.error(resErr.error, {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  };

  const loadUsers = (page, search) => {
    Fetch(`sports/sports-center/${page ? '?page=' + page : ''}${search ? '&search=' + search : ''}`).then(res => {
      setLoader(false)
      if (res.status) {
        setPage(page ? page : 1)
        setData(res.data);
      }
    })
  };
  return (
    <>
      <ToastContainer />
      <Container maxWidth="lg">
        <Grid container spacing={3} alignItems='center' className="owners py-5">
          <Grid
            item
            xs={12}
            sm={6}
          >
            <h3
              className="topheading
            "
            >
              {" "}
              Centre
            </h3>
          </Grid>
          <Grid
            className="buttons headSearch headSearch-mobile"
            item
            xs={12}
            sm={6}
          >
            <TypeSearch handleChange={(val) => loadUsers(1, val)} />
            <Link to="/centre/add" style={{ textDecoration: "none" }}>
              <Button
                className="add-btn"
                variant="contained"
              >
                <AddCircleOutlineOutlined style={{ marginRight: "6px" }} />
                Add Centre
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <div className="WidgetLg" style={{ marginBottom: "85px" }}>
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"> S.No.</TableCell>
                  <TableCell align="left">Centre</TableCell>
                  <TableCell align="left"> Location </TableCell>
                  <TableCell align="left">Timings</TableCell>
                  <TableCell align="left"> Contact </TableCell>
                  <TableCell align="center"> Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  loader ?
                    <TableRow>
                      <TableCell colSpan={6} align="center"><CircularProgress /></TableCell>
                    </TableRow>
                    :
                    data?.results?.length ?
                      data?.results?.filter((val) => {
                        if (searchTerm === "") {
                          return val;
                        } else if (
                          val.location
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                          //   ||
                          // val.name.toLowerCase().includes(searchTerm.toLowerCase())
                        ) {
                          return val;
                        }
                      })
                        .map((admin, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell align="center">{(page - 1) * paginationSize + i + 1}</TableCell>
                              <TableCell align="left">
                                {admin?.center_name}
                              </TableCell>
                              <TableCell align="left">{admin?.location}</TableCell>
                              <TableCell align="left">{moment(admin?.timefrom, "HH:mm:ss").format("hh:mm A")} - {moment(admin?.timeto, "HH:mm:ss").format("hh:mm A")}</TableCell>
                              <TableCell align="left">
                                {admin?.phone_no}
                              </TableCell>
                              <TableCell align="center">
                                <div className="flex align-center justify-center">
                                  <Link to={`/centre/${admin?.id}`}>
                                    <IconButton
                                      variant="contained"
                                      title="Edit"
                                      type="submit"
                                      style={{
                                        padding: "0",
                                        boxShadow: "none",
                                        border: "none",
                                        background: "none",
                                        minWidth: "0px",
                                      }}
                                    >
                                      <Edit
                                        style={{ color: "#17a248", margin: "-5px" }}
                                      />
                                    </IconButton>
                                  </Link>
                                  <IconButton
                                    title="Delete"
                                    onClick={() => handleClickOpen(admin?.id)}
                                    style={{
                                      padding: "0",
                                      boxShadow: "none",
                                      border: "none",
                                      background: "none",
                                      minWidth: "0px",
                                    }}
                                  >
                                    <Delete style={{ color: "red", margin: "8px" }} />
                                  </IconButton>
                                </div>
                                {/* <IconButton
                            onClick={downloadTxtFile}
                            style={{
                              padding: "0",
                              boxShadow: "none",
                              border: "none",
                              background: "none",
                              minWidth: "0px",
                            }}
                          >
                            <SaveAlt />
                          </IconButton> */}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      :
                      <TableRow>
                        <TableCell colSpan={6} align="center">No Data Found</TableCell>
                      </TableRow>
                }
              </TableBody>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Delete Centre</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this Centre?
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="Buttonss">
                  <Button onClick={handleClose}>No</Button>
                  <Button onClick={() => deleteUser()}>Yes</Button>
                </DialogActions>
              </Dialog>
            </Table>
          </TableContainer>
          {data?.results?.length ? <Pagination page={page} data={data} sendPageNumber={loadUsers} /> : null}
        </div>
      </Container>
    </>
  );
}