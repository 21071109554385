import React from "react";
import { IconButton, TableRow, TableHead, TableCell, TableBody, Table, Container, Grid, CircularProgress } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { TableContainer } from "@mui/material";
import Pagination from "../../component/Page/Pagination";
import Fetch from "../../common/fetch";
import { paginationSize } from "../../common/utilits";
import TypeSearch from "../../component/search";
export default function UserSportscenter() {
  const [data, setData] = useState({});
  const [page, setPage] = useState(1)
  const [loader, setLoader] = useState(true)
  useEffect(() => {
    document.title = "Centre";
    loadUsers();
  }, []);

  const loadUsers = (page, search) => {
    Fetch(`sports/sport-center-listing/${page ? '?page=' + page : ''}${search ? '&search=' + search : ''}`).then(res => {
      setLoader(false)
      if (res.status) {
        setPage(page ? page : 1)
        setData(res.data);
      }
    })
  };
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={3} alignItems='center' className="owners py-5">
          <Grid
            item
            xs={12}
            sm={6}
          >
            <h3
              className="topheading
            "
            >
              {" "}
              Centre
            </h3>
          </Grid>
          <Grid
            className="buttons headSearch"
            item
            xs={12}
            sm={6}
          >
            <TypeSearch handleChange={(val) => loadUsers(1, val)} />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <div className="WidgetLg" style={{ marginBottom: "85px" }}>
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"> S.No.</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left"> Location </TableCell>
                  <TableCell align="left">Owner</TableCell>
                  <TableCell align="left"> Contact No.</TableCell>
                  <TableCell align="left"> Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  loader ?
                    <TableRow>
                      <TableCell colSpan={6} align="center"><CircularProgress /></TableCell>
                    </TableRow>
                    :
                    data?.results?.length ?
                      data?.results?.map((admin, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell align="center">{(page - 1) * paginationSize + i + 1}</TableCell>
                            <TableCell align="left">
                              {admin?.center_name}
                            </TableCell>
                            <TableCell align="left">{admin?.location}</TableCell>
                            <TableCell align="left">{admin?.owner_name}</TableCell>
                            <TableCell align="left">
                              {admin?.phone_no}
                            </TableCell>
                            <TableCell align="left">
                              <div className="flex align-center">
                                <Link to={`/centre/detail/${admin?.id}`}>
                                  <IconButton
                                    variant="contained"
                                    title="View"
                                    type="submit"
                                    style={{
                                      padding: "0",
                                      boxShadow: "none",
                                      border: "none",
                                      background: "none",
                                      minWidth: "0px",
                                    }}
                                  >
                                    <Visibility
                                      style={{ color: "#454545", margin: "-5px" }}
                                    />
                                  </IconButton>
                                </Link>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                      :
                      <TableRow>
                        <TableCell colSpan={6} align="center">No Data Found</TableCell>
                      </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          {data?.results?.length ? <Pagination page={page} data={data} sendPageNumber={loadUsers} /> : null}
        </div>
      </Container>
    </>
  );
}