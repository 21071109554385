import React, { useEffect, useState } from "react";
import {
    FormControl,
    InputAdornment,
    TextField,
    createStyles,
    makeStyles
} from "@material-ui/core";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles(() => {
    return createStyles({
        searchInput: {
            margin: "0",
            '& .MuiOutlinedInput-adornedEnd': {
                padding: 0,
                background: '#fff',
                borderRadius: '20px'
            }
        }
    });
});

const TypeSearch = ({ handleChange,searchVal='' }) => {
    const { searchInput } = useStyles();
    const [search, setSearch] = useState('')
    const [searchStart, setSearchStart] = useState(false)
    useEffect(()=>{
        setSearch(searchVal)
    },[searchVal])
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchStart) {
                setSearchStart(false)
                handleChange && handleChange(search)
            }
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [search])
    const onSearchChange = (e) => {
        setSearchStart(true)
        setSearch(e.target.value)
    }
    return (
        <div id="app">
            <FormControl className={searchInput}>
                <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Search..."
                    value={search}
                    onChange={onSearchChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>
        </div>
    );
};

export default TypeSearch;
