import { Card, CardContent, Grid, Container } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    MealTypeList: {
        display:'flex',
        '& p':{
            fontSize:'14px',
            color:'#747474'
        }
    },
    CardContent:{
        borderBottom:'1px solid #cccccf',
    },
    MealType:{
        background:'#f8f7fe',
        display:'flex',
        // justifyContent:'center',
        alignItems:'center',
        paddingLeft: '30px',
        '& h3':{
            margin:0,
            fontSize: '17px'
        }
    }
}))
export default function CardContentList({items}){
    const className = useStyles()
    return<CardContent style={{ padding: 0 }}>
    <div style={{ textAlign: "center", color: "#122A4D" }}>
        <Grid container className={className.CardContent}>
            <Grid item xs={12} sm={3} className={className.MealType}>
                <h3 style={{ marginBottom: "0px" }}>{items.dietTime}</h3>
            </Grid>
            <Grid item xs={12} sm={9} style={{ padding: "20px 30px 20px 15px" }}>
                {/* {
                    items?.dietDetails?.map((items,key)=><ListType text={items} key={key}/>)
                } */}
                <ListType text={items?.dietDetails}/>
            </Grid>
        </Grid>
    </div>
</CardContent>
}
function ListType({text}) {
    const className = useStyles()
    return <Box className={className.MealTypeList}>
        <p style={{ marginTop: "0px", marginBottom: "3px", marginLeft: "10px",textAlign:'left' }}><div style={{ whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html:text}}></div></p>
    </Box>
}