import "./Data.css";
import { useState, useEffect } from "react";
import { TableRow, TableHead, TableCell, TableBody, Button, Container, Grid, Box, CircularProgress } from "@material-ui/core";
import { Delete, Edit, AddCircleOutlineOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { makeStyles, alpha } from "@material-ui/core/styles";
import { TableContainer, Paper, Table, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Pagination from "../../component/Page/Pagination";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { arrayString, paginationSize } from "../../common/utilits";
import TypeSearch from "../../component/search";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 670,
  },
  search: {
    position: "relative",
    variant: "outlined",
    borderRadius: "25px",
    color: "black",

    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 18),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    top: "0px",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "15ch",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  },
}));

export default function WidgetLg() {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [searchTerm, setsearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1)
  const [setid, SetsetId] = useState()
  const [loader, setLoader] = useState(true)
  const handleClickOpen = (id) => {
    setOpen(true);
    SetsetId(id)
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadUsers();
  }, []);
  const deleteUser = () => {
    Fetch(`sports/user/${setid}/`, null, { method: 'delete' }).then(res => {
      if (res?.status) {
        setOpen(false);
        loadUsers(page);
        toast.success("User Deleted Successfully.", {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        let resErr = arrayString(res)
        toast.error(resErr.error, {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  };

  const loadUsers = (page, search) => {
    Fetch(`sports/user/${page ? '?page=' + page : ''}${search ? '&search=' + search : ''}`).then(res => {
      setLoader(false)
      if (res?.status) {
        setPage(page ? page : 1)
        setData(res.data);
      }
    })
  };
  return (
    <>
      <ToastContainer />
      <Container maxWidth="lg">
        <Grid container spacing={3} alignItems='center' className="usermg py-5">
          <Grid
            item
            xs={12}
            sm={6}
          >
            <h3> User Management</h3>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className="butt headSearch"
          >
            <TypeSearch handleChange={(val) => loadUsers(1, val)} />
            <Link to="/user/add" style={{ textDecoration: "none" }}>
              <Button
                className="add-btn"
                variant="contained"
                type="submit"
              >
                <AddCircleOutlineOutlined style={{ marginRight: "6px" }} />
                Add User
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <div className="WidgetLg" style={{ marginBottom: "85px" }}>
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No.</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left"> Location</TableCell>
                  <TableCell align="center"> Email</TableCell>
                  <TableCell align="center"> Contact No.</TableCell>
                  <TableCell align="left"> Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  loader ?
                    <TableRow>
                      <TableCell colSpan={6} align="center"><CircularProgress /></TableCell>
                    </TableRow>
                    :
                    data?.results?.length ? data?.results?.filter((val) => {
                      if (searchTerm === "") {
                        return val;
                      } else if (
                        val.location.toLowerCase().includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                      .map((admin, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell align="center">{(page - 1) * paginationSize + i + 1}</TableCell>
                            <TableCell align="left">
                              {admin?.name}
                            </TableCell>
                            <TableCell align="left">{admin.location}</TableCell>
                            <TableCell align="center">
                              {admin?.email}
                            </TableCell>
                            <TableCell align="center">
                              {admin?.phone_no}
                            </TableCell>
                            <TableCell align="left">
                              <Box className='flex align-center'>
                                <Link to={`/user/${admin.id}`}>
                                  <Button
                                    variant="contained"
                                    title="Edit"
                                    className="btn-back"
                                    type="submit"
                                    style={{
                                      padding: "0",
                                      boxShadow: "none",
                                      border: "none",
                                      background: "none",
                                      minWidth: "0px",
                                    }}
                                  >
                                    <Edit style={{ color: "#17a248" }} />
                                  </Button>
                                </Link>
                                <Button
                                  onClick={() => handleClickOpen(admin.id)}
                                  className="btn-back"
                                  title="Delete"
                                  style={{
                                    padding: "0",
                                    boxShadow: "none",
                                    border: "none",
                                    background: "none",
                                    minWidth: "0px",
                                  }}
                                >
                                  <Delete style={{ color: "red", margin: "7px" }} />
                                </Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })
                      :
                      <TableRow>
                        <TableCell colSpan={6} align="center">No Data Found</TableCell>
                      </TableRow>
                }
              </TableBody>
            </Table>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Delete User</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this user?
                </DialogContentText>
              </DialogContent>
              <DialogActions className="Buttonss">
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => deleteUser()}>OK</Button>
              </DialogActions>
            </Dialog>
          </TableContainer>
          {data?.results?.length ? <Pagination page={page} data={data} sendPageNumber={loadUsers} /> : null}
        </div>
      </Container>
    </>
  );
}
