import React, { useState, useEffect } from "react";
const Pagination = ({ data, sendPageNumber, page, pageSize }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPrevDisable, setIsPrevDisable] = useState(false);
  const [isNextDisable, setIsNextDisable] = useState(false);
  useEffect(() => {
    setCurrentPage(page ? page : 1)
  }, [page])
  useEffect(() => {
    if (Object.keys(data)?.length) {
      let count = Math.ceil(data?.count / (pageSize ? pageSize : 10))
      setTotalPages(count);
    }
  }, [data]);
  useEffect(() => {
    if (currentPage > 1) {
      setIsPrevDisable(false);
    } else {
      setIsPrevDisable(true);
    }
    if (currentPage < totalPages) {
      setIsNextDisable(false);
    } else {
      setIsNextDisable(true);
    }
  }, [currentPage, totalPages]);
  const getPrevPage = () => {
    if (currentPage > 1) {
      setIsPrevDisable(false);
      setCurrentPage(currentPage - 1);
      sendPageNumber(currentPage - 1)
    } else {
      setIsPrevDisable(true);
    }
  };

  const getNextPage = () => {
    if (currentPage < totalPages) {
      setIsNextDisable(false);
      setCurrentPage(currentPage + 1);
      sendPageNumber(currentPage + 1)

    } else {
      setIsNextDisable(true);
    }
  };

  return (
    <>
      <div className="pagination-btn">
        <button disabled={isPrevDisable} className={`page-btn ${isPrevDisable ? 'disabled' : ''}`}
          onClick={(e) => {
            getPrevPage(e);
          }}
        >
          Prev
        </button>
        <ul className="ul-page">
          {
            [...Array(totalPages)].map((d, key) => <li className={`li-btn ${currentPage === (key + 1) ? 'active' : ''}`} onClick={() => { setCurrentPage(key + 1); sendPageNumber(key + 1) }}>{key + 1}</li>)
          }
        </ul>
        <button disabled={isNextDisable}
          className={`page-btn next ${isNextDisable ? 'disabled' : ''}`} onClick={(e) => {
            getNextPage(e);
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Pagination;
