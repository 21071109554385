import { useEffect, useRef } from "react";
export default function DropDownMenu({ open, onClose, className, children, mobile=null }) {
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                console.log('hello ');
                onClose && onClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);
    if (!open) {
        return<></>;
    }
    return (
        <div className={`dropdown-dialog ${mobile ? 'menu-mobile' : ''} ${className ? className : ''}`} ref={wrapperRef}>
            {children}
        </div>
    );
}