import { React, useState } from "react";
import { Button, CssBaseline, TextField, Grid, MenuItem, Select, Typography, InputLabel, FormControl, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, IconButton } from "@mui/material";
import "./Signup.css";
import {
  PersonRounded,
  Email,
  Phone,
  VpnKeyOutlined,
  Wc,
  Visibility, VisibilityOff
} from "@material-ui/icons";
import { CircularProgress, Stack, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Fetch from "../../common/fetch";
import { arrayString, onKeyPress } from "../../common/utilits";
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },

  paper: {
    margin: theme.spacing(2, 4),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      margin: "16px 0 0",
    },
  },
  btnAuth: {
    backgroundColor: "rgba(12,11,69,255) !important",
    color: "white",
    borderRadius: " 22px 22px",
    padding: "11px 65px !important",
    fontSize: "18px",
    marginTop: "90px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  labelform: {
    color: "rgba(12,11,69,255)",
    padding: "5px",
    paddingLeft: 0,
    display: "flex",
    fontSize: "15px",
    letterSpacing: "2px",
    alignItems: 'center',
    fontWeight: "600",
    marginTop: "30px",
    '& .icon': {
      fontSize: '15px !important'
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginTop: "15px",
      '& .icon': {
        fontSize: '14px !important'
      }
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
const emailRegx = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "Please enter your name")
    .required("Name is required.")
    .matches(/^[A-Za-z ]*$/, "Only alphabets are required."),
  // .matches(countryNameRegex, "Only alphabets are required."),
  email: yup
    .string()
    .email("Please enter a valid email address .")
    .matches(emailRegx, "Invalid Email ID...")
    .required("Email is required."),
  phone_no: yup
    .string()
    .matches(phoneRegExp, "Phone number must contains only digits.")
    .min(10, "Phone number should not be less than 10 digits.")
    .max(10, "Phone number should not be more than 10 digits.")
    .required("Phone number is required."),
  location: yup.string().required("Location is required."),
  gender: yup.string().required("Please select gender"),
  password: yup
    .string()
    .matches(
      PASSWORD_REGEX,
      " Password must have at least 8 characters and the combination of the following: uppercase letter, lowercase letter, numbers, and symbols."
    )
    .required("Password is required."),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required.")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Password does not match"),
    }),
});

export default function SignInSide() {
  const mobile = useMediaQuery('(max-width:600px)')
  const [role, setRole] = useState("Owner");
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mes, setMes] = useState();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [visible, setVisible] = useState(false);
  const onSubmit = (e) => {
    // e.preventDefault();
    if (formik.isValid) {
      setIsLoading(true);
      let param = { ...formik.values }
      delete param.confirmPassword
      Fetch("users/user-register/", { ...formik.values, role: role }, { method: 'post' }).then(res => {
        setIsLoading(false);
        if (res.status) {
          toast.success("Account has been created successfully. Please check your email address to verify your account.", {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/");
          }, 3000);
          setVisible(false);
        } else {
          let resErr = arrayString(res)
          toast.error(resErr.error, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
    }
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowPasswordConfirm(!showPasswordConfirm);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_no: "",
      password: "",
      location: '',
      gender: "",
      confirmPassword: "",
    },
    validateOnBlur: true,
    onSubmit,

    validationSchema: validationSchema,
  });
  const handleChange = (event) => {
    setRole(event.target.value);
  };
  const handleGender = () => {
    setOpen(!open);
  };
  return (
    <Grid container component="main">
      <ToastContainer />
      <CssBaseline />
      <Grid md={12}>
        <div className="main">
          <div className={classes.paper}>
            <div>
              <Grid
                align="start"
                style={{
                  marginTop: "8px",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                <h2 className="signupheading"> Sign Up</h2>
                {/* {message && <div>{message}</div>} */}
                <Typography
                  variant="caption"
                  gutterBottom
                  style={{ fontSize: "15px" }}
                >
                  Fill the details below to SignUp
                </Typography>
              </Grid>
              <Stack
                direction="row"
                spacing={2}
                style={{ justifyContent: "center", marginTop: "34px" }}
              >
                <FormControl>
                  <RadioGroup
                    defaultValue="owner"
                    checked={role === "owner"}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      onChange={handleChange}
                      checked={role === "Owner"}
                      value="Owner"
                      control={<Radio />}
                      label="Owner"
                      inputProps={{ "aria-label": "Owner" }}
                    />
                    <FormControlLabel
                      checked={role === "Coach"}
                      onChange={handleChange}
                      value="Coach"
                      control={<Radio />}
                      label="Coach"
                      inputProps={{ "aria-label": "Coach" }}
                    />
                    <FormControlLabel
                      checked={role === "User"}
                      onChange={handleChange}
                      value="User"
                      control={<Radio />}
                      label="User"
                      inputProps={{ "aria-label": "User" }}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </div>

            <form method="POST" noValidate onSubmit={formik.handleSubmit}>
              <InputLabel
                className={`${classes.labelform} Input`}
              >
                <PersonRounded
                  style={{ fontSize: "18px", marginRight: "4px" }}
                />
                NAME
              </InputLabel>
              <TextField
                inputProps={{ maxLength: 50 }}
                error={Boolean(formik.touched.name && formik.errors.name)}
                margin="normal"
                style={{ marginTop: "10px" }}
                required
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                autoComplete="name"
              />
              <Grid container spacing={mobile ? 1 : 3}>
                <Grid item sm={12} md={6}>
                  <InputLabel
                    className={`${classes.labelform} InputLabel`}
                  >
                    <Email style={{ fontSize: "18px", marginRight: "4px" }} />
                    EMAIL
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    style={{ marginTop: "10px" }}
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    margin="normal"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values.email}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <InputLabel
                    className={`${classes.labelform} Input`}
                  >
                    <PersonRounded
                      style={{ fontSize: "18px", marginRight: "4px" }}
                    />
                    Location
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(formik.touched.location && formik.errors.location)}
                    margin="normal"
                    style={{ marginTop: "10px" }}
                    required
                    fullWidth
                    helperText={formik.touched.location && formik.errors.location}
                    name="location"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.location}
                    autoComplete="location"
                  />
                </Grid>
              </Grid>
              {/* {visible ? (<p style={{ color: "red", margin: "0px",fontSize: "12px" }}>{err}</p>) : ""} */}

              <Grid container spacing={mobile ? 1 : 3}>
                <Grid item sm={12} md={6}>
                  <InputLabel
                    id="gender"
                    className={`${classes.labelform} InputLabel`}
                  >
                    <img src='/icon/gender_ico.png' width='18px' style={{ marginRight: '5px' }} />
                    {/* <Wc style={{ fontSize: "18px", marginRight: "4px" }} /> */}
                    GENDER
                  </InputLabel>
                  <Select
                    error={Boolean(
                      formik.touched.gender && formik.errors.gender
                    )}
                    fullWidth
                    required
                    helperText={formik.touched.gender && formik.errors.gender}
                    open={open}
                    onClose={handleGender}
                    onOpen={handleGender}
                    name="gender"
                    value={formik.values.gender}
                    style={{ marginTop: "10px" }}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>--Select--</em>
                    </MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {/* {mes && <div style={{ color: "red" }}>{mes}</div>} */}
                  {formik.touched.gender && (
                    <div className="MuiFormHelperText-root Mui-error Mui-required">{formik.errors.gender}</div>
                  )}
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel
                    className={`${classes.labelform} InputLabel`}
                  >
                    <Phone style={{ fontSize: "18px", marginRight: "4px" }} />
                    CONTACT NUMBER
                  </InputLabel>

                  <TextField
                    inputProps={{ maxLength: 13 }}
                    error={Boolean(
                      formik.touched.phone_no && formik.errors.phone_no
                    )}
                    margin="normal"
                    required
                    fullWidth
                    helperText={
                      formik.touched.phone_no && formik.errors.phone_no
                    }
                    onKeyPress={onKeyPress}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    style={{ marginTop: "10px" }}
                    name="phone_no"
                    type="tel"
                    autoComplete="number"
                  />
                </Grid>
              </Grid>
              <InputLabel
                className={`${classes.labelform} InputLabel`}
              >
                <VpnKeyOutlined
                  style={{ fontSize: "18px", marginRight: "4px" }}
                />
                PASSWORD
              </InputLabel>
              <TextField
                inputProps={{ maxLength: 40 }}
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                margin="normal"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                style={{ marginTop: "10px" }}
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleClickShowPassword}
                      >
                        {showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <InputLabel
                className={`${classes.labelform} InputLabel`}
              >
                <VpnKeyOutlined
                  style={{ fontSize: "18px", marginRight: "4px" }}
                />
                CONFIRM PASSWORD
              </InputLabel>

              <TextField
                inputProps={{ maxLength: 40 }}
                error={Boolean(
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                )}
                fullWidth
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                margin="normal"
                name="confirmPassword"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                style={{ marginTop: "10px" }}
                type={showPasswordConfirm ? "text" : "password"}
                value={formik.values.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleClickShowConfirmPassword}
                      >
                        {showPasswordConfirm ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Grid container justify="center">
                <Button
                  // disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                  size="medium"
                  // onClick={showAlert}
                  // onClick={(e) => onSubmit(e)}
                  color="primary"

                  className={classes.btnAuth}
                >
                  {isLoading ? <CircularProgress Shrink /> : "Sign Up"}
                </Button>
              </Grid>

              <Grid container>
                <Grid item xs>
                  <Grid
                    item
                    container
                    justify="center"
                    style={{ display: "flex", marginTop: "30px" }}
                  >
                    <p style={{ marginRight: "6px", marginTop: "0px" }}>
                      Already have an account?
                    </p>
                    <a
                      href="/"
                      variant="body2"
                      style={{
                        textDecoration: "none",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {"Sign In here!"}
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
