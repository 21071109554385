import { React } from "react";
import { Button, CssBaseline, TextField, Grid, Typography, InputLabel, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Email } from "@material-ui/icons";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import "./Forgetpassword.css";
import CircularProgress from "@mui/material/CircularProgress";
import Fetch from "../../common/fetch";
import { arrayString } from "../../common/utilits";
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  labelform: {
    color: "rgba(12,11,69,255)",
    padding: "5px",
    paddingLeft: 0,
    display: "flex",
    fontSize: "15px",
    letterSpacing: "2px",
    fontWeight: "600",
    marginTop: "30px",
    alignItems: 'center',
    '& .icon': {
      fontSize: '15px !important'
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginTop: "15px",
      '& .icon': {
        fontSize: '14px !important'
      }
    },
  },
  btnAuth: {
    backgroundColor: "rgba(12,11,69,255) !important",
    color: "white",
    borderRadius: " 28px 28px",
    padding: "10px 45px !important",
    fontSize: "18px",
    marginTop: "134px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      fontSize: "16px",
    },
  },
  paper: {
    display: "flex",
    marginTop: "90px",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const emailRegx = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .matches(emailRegx, "Invalid Email ID...")
    .required("Email is required."),
});

export default function SignInSide() {
  useEffect(() => {
    document.title = "Forget Password";
  }, []);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (e) => {
    setIsLoading(true);
    if (formik.isValid) {
      Fetch("users/send-password-reset-link/", formik.values, { method: 'post' }).then(res => {
        setIsLoading(false);
        if (res.status) {
          toast.success("Password reset link sent to your email. Please check your inbox.", {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          let resErr = arrayString(res)
          toast.error(resErr.error, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
    }
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <Grid container component="main" className={classes.root}>
      <ToastContainer />
      <CssBaseline />
      <Grid md={12}>
        <div className="bgimgforgot">
          <Container maxWidth="false">
            <div className="main">
              <Grid
                container
                spacing={3}
                className="secondforgot"
              >
                <Grid item xs={12} md={6}>
                  <div className="display" style={{ color: "white" }}>
                    <p style={{ fontSize: "20px" }}>WELCOME TO</p>
                    <Typography component="h1" variant="h5" className="Sig">
                    Centre <br></br>Management
                      <hr
                        style={{
                          width: "36%",
                          textAlign: "left",
                          marginLeft: "0",
                        }}
                      />
                    </Typography>
                    {/* <h4 style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      Lorem lpsum ddummycontent
                    </h4>
                    <p className="pp" style={{ color: "white" }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      <br />
                      Mauris ac ornare enim{" "}
                    </p> */}
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className={classes.paper}>
                    {/* {message && <div style={{fontSize:"30px"}}>{message}</div>} */}
                    <Typography component="h1" variant="h4" className={`forgetPass Sign`}>
                      Forget Password
                    </Typography>
                    <p className="p">
                      Enter your registered email to reset your password
                    </p>

                    <form
                      className={classes.form}
                      method="POST"
                      noValidate
                      onSubmit={formik.handleSubmit}
                    >
                      <InputLabel
                        className={`InputLabel ${classes.labelform}`}
                      >
                        <Email className="icon" />
                        EMAIL
                      </InputLabel>
                      <TextField
                        inputProps={{ maxLength: 50 }}
                        error={Boolean(
                          formik.touched.email && formik.errors.email
                        )}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        margin="normal"
                        value={formik.values.email}
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="email"
                      />

                      <Grid container justify="center">
                        <Button
                          className={`btn-submit ${classes.btnAuth}`}
                          type="submit"
                          variant="outlined"
                          size="medium"
                          color="primary"
                        >
                          {
                            isLoading ?
                              <CircularProgress Shrink />
                              :
                              'SEND RESET LINK'
                          }

                        </Button>
                      </Grid>
                      <Grid container className="link">
                        <Grid item container justify="center">
                          <Link
                            style={{
                              textDecoration: "none",
                              fontWeight: "bold",
                            }}
                            to="/"
                            variant="body2"
                          >
                            {"Sign In here!"}
                          </Link>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </Grid>
    </Grid>
  );
}
