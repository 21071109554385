import { MenuItem, DialogTitle, InputLabel, IconButton, Button, Dialog, DialogActions, DialogContent, FormHelperText } from '@mui/material';
import { Select, OutlinedInput, Checkbox } from '@material-ui/core';
import Fetch from '../../common/fetch';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from "yup";
import { toast } from 'react-toastify';
import { arrayString } from '../../common/utilits';

const validationSchema = yup.object().shape({
    user_id: yup.array().min(1, 'Username is required')
});
export default function Assign({ open,userIdsSelected, handleAssign, userList }) {
    const [state, setState] = useState([])
    useEffect(()=>{
        if(userIdsSelected?.length){
            JSON.stringify(userIdsSelected)
            setState(userIdsSelected.map(items=>JSON.stringify(items)))
        }
    },[userIdsSelected])
    const onSubmit = () => {
        let param = [...state]?.map(items => JSON.parse(items)?.id)
        Fetch('assign-library/', { user_id: param }, { method: 'post' }).then(res => {
            if (res?.status) {
                handleAssign()
                toast.success("Library Assigned Succesfully.", {
                    hideProgressBar: false,
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                let resErr = arrayString(res)
                toast.error(resErr.error, {
                    hideProgressBar: false,
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        })
    }
    const onChange = (e) => {
        const {
            target: { value },
        } = e;
        setState(value);
    }
    const formik = useFormik({
        initialValues: { user_id: state },
        validateOnChange: true,
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });
    return <Dialog open={open} className='smDialog' onClose={handleAssign}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle >Assign</DialogTitle>
            <IconButton onClick={handleAssign}>
                <CloseIcon style={{ color: "rgba(12,11,69,255)" }} />
            </IconButton>
        </div>
        <form onSubmit={formik.handleSubmit}>
            <DialogContent>
                <InputLabel className="Input"
                    style={{
                        color: "rgba(12,11,69,255)",
                        fontSize: "12px",
                    }}>
                    Username:
                </InputLabel>
                <Select
                    error={Boolean(
                        formik.touched.user_id && formik.errors.user_id
                    )}
                    fullWidth
                    variant="outlined"
                    required
                    multiple
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected?.map(item => JSON.parse(item)?.name)?.join(', ')}
                    helperText={formik.touched.user_id && formik.errors.user_id}
                    name="user_id"
                    value={formik.values.user_id}
                    style={{ marginTop: "16px" }}
                    onChange={(e) => {
                        formik.handleChange(e);
                        onChange(e)
                    }}
                    onBlur={formik.handleBlur}
                >
                    <MenuItem disabled value="">
                        <em>--Select--</em>
                    </MenuItem>
                    {
                        userList?.map(items => <MenuItem value={JSON.stringify(items)}>
                            <Checkbox checked={state.indexOf(JSON.stringify(items)) > -1} />
                            {items?.name}
                        </MenuItem>)
                    }
                </Select>
                {/* {mes && <div style={{ color: "red" }}>{mes}</div>} */}
                {formik.touched.user_id && (
                    <FormHelperText error={formik.touched.user_id}>{formik.errors.user_id}</FormHelperText>
                )}
            </DialogContent>
            <DialogActions
                style={{ justifyContent: "center", marginBottom: "15px" }}>
                <Button type='submit' variant="contained" style={{
                    backgroundColor: "rgba(12,11,69,255)",
                    color: "white", borderRadius: " 22px 22px", padding: "10px 65px"
                }}>Assign</Button>
            </DialogActions>
        </form>
    </Dialog>
}