import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputLabel, Container, Grid, Paper, Button, FormHelperText, Checkbox, MenuItem, Select, Box, Switch } from "@material-ui/core";
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import AppLayout from "../../layout/appLayout";
import CircularProgress from "@mui/material/CircularProgress";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { arrayString } from "../../common/utilits";
import Back from "../../component/backIco";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const emailRegx = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
let validateError = {
  name: Yup.string()
    .max(25, "Must be 25 characters or less")
    .required("Name is required.")
    .matches(/^[A-Za-z ]*$/, "Only alphabets are required."),
  email: Yup.string()
    .email("Email is invalid.")
    .required("Email is required.")
    .matches(emailRegx, "Invalid Email ID..."),
  phone_no: Yup.string()
    .min(10, "Phone number not less than 10 character.")
    .max(10, "Phone no not more than 10 character.")
    .required("Phone number is required.")
    .matches(phoneRegExp, "Only numbers are allowed."),
  location: Yup.string().required("Location is required."),
  gender: Yup.string().required("Location is required."),
  sports_center: Yup.array().min(1, "Centre is required."),
  speciallsation: Yup.string().required("Specialization is required."),
}
const AddCoach = () => {
  let navigate = useNavigate();
  const param = useParams()
  validateError = param.id === 'add' ? {
    ...validateError, password: Yup
      .string()
      .matches(PASSWORD_REGEX, " Password must have at least 8 characters and the combination of the following: uppercase letter, lowercase letter, numbers, and symbols.")
      .required("Password is required."),
  } : validateError
  const validationSchema = Yup.object().shape(validateError);
  const classes = useStyles();
  const [error, setError] = useState("");
  const [sports, setSports] = useState([]);
  const [status,setStatus]=useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [sportCenterIds,setSportCenterIds]=useState([])
  const [state, setState] = useState({
    name: "",
    email: "",
    phone_no: "",
    location: "",
    gender: '',
    speciallsation: "",
    sports_center: [],
  })
  useEffect(() => {
    document.title = "Add Coach";
    handleSports();
    if (param.id !== 'add') {
      loadUser(param.id)
    }
  }, [param]);
  useEffect(() => {
    if (sports?.length && sportCenterIds?.length) {
      let data = []
      sports.map(items => {
        if (sportCenterIds.includes(items.id)) {
          data.push(JSON.stringify(items))
        }
      })
      setState({
        ...state,
        sports_center: data,
      });
    }
  }, [sportCenterIds, sports])
  const loadUser = (id) => {
    Fetch(`sports/coaches/${id}/`).then(res => {
      if (res.status) {
        const { data } = res
        setStatus(data?.status)
        setState({
          name: data?.name,
          email: data?.email,
          phone_no: data?.phone_no,
          location: data?.location || '',
          gender: data?.gender,
          speciallsation: data?.speciallsation || '',
          sports_center: [],
        });
        setSportCenterIds(data?.sports_center_id)
      }
    })
  };
  const onSubmit = (e) => {
    if (formik.isValid) {
      setIsLoading(true);
      let val = { ...formik.values, sports_center: formik.values.sports_center.map(items => JSON.parse(items)?.id) }
      if (param.id !== 'add') {
        delete val.email
      }
      Fetch(`sports/coaches/${param.id === 'add' ? '' : param.id + '/'}`, val, { method: param.id === 'add' ? 'post' : 'patch' }).then(res => {
        setIsLoading(false);
        if (res.status) {
          toast.success(`Coach ${param.id === 'add' ? 'Added' : 'Updated'} Successfully`, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/coachmanagement");
          }, 3000)
        } else {
          let resErr = arrayString(res)
          toast.error(resErr.error, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
    };
  }

  const handleSports = () => {
    Fetch("sports/sports-center/sports-center-owner/").then(res => {
      if (res.status) {
        setSports(res?.data?.length ? res?.data : []);
      }
    })
  };
  const initialValues = param.id === 'add' ? {
    ...state,
    password: "",
  } : state
  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  const onChangeStatus=(e)=>{
    setStatus(e.target.checked)
    Fetch('users/deactivate-user/',{user_id:Number(param.id),role:'Coach'},{method:'post'}).then(res=>{
      if(res.status){
        toast.success(`Coach has been ${e.target.checked?'Activated':'Deactivated'} Successfully.`, {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  }
  return (
    <AppLayout>
      <ToastContainer />
      <Container>
        <h3 className="flex align-center" style={{ padding: "10px 10px 10px 0px" }}><Back/> {param.id === 'add' ? 'Add New' : 'Edit'} Coach</h3>
        <Paper elevation={3}>
          <div className={classes.root} style={{ padding: "20px" }}>
            <form method="POST" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    margin="normal"
                    required
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    name="name"
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    autoComplete="name"
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Email
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    disabled={param.id !== 'add'}
                    helperText={formik.touched.email && formik.errors.email}
                    margin="normal"
                    name="email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    variant="outlined"
                  />
                  <p style={{ color: "red", margin: "0px" }}>{error}</p>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    id="gender"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    error={Boolean(
                      formik.touched.gender && formik.errors.gender
                    )}
                    fullWidth
                    variant="outlined"
                    required
                    helperText={formik.touched.gender && formik.errors.gender}
                    name="gender"
                    value={formik.values.gender}
                    style={{ marginTop: "16px" }}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>--Select--</em>
                    </MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {/* {mes && <div style={{ color: "red" }}>{mes}</div>} */}
                  {formik.touched.gender && (
                    <div className="MuiFormHelperText-root Mui-error Mui-required">{formik.errors.gender}</div>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Phone Number
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 13 }}
                    error={Boolean(
                      formik.touched.phone_no && formik.errors.phone_no
                    )}
                    margin="normal"
                    value={formik.values.phone_no}
                    required
                    fullWidth
                    helperText={
                      formik.touched.phone_no && formik.errors.phone_no
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="phone_no"
                    type="tel"
                    autoComplete="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    id="sports_center"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Centre
                  </InputLabel>
                  <Select
                    id="sports_center"
                    style={{
                      marginTop: "16px",
                    }}
                    error={
                      formik.touched.sports_center && Boolean(
                        formik.errors.sports_center
                      )}
                    helperText={
                      formik.touched.sports_center && formik.errors.sports_center
                    }
                    inputProps={{ maxLength: 50 }}
                    margin="normal"
                    required
                    multiple
                    fullWidth
                    onBlur={formik.handleBlur}
                    renderValue={(selected) => selected?.map(item => JSON.parse(item)?.center_name)?.join(', ')}
                    onChange={(e) => { formik.handleChange(e) }}
                    name="sports_center"
                    variant="outlined"
                    value={formik.values.sports_center}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>--- Select Center ---</em>
                    </MenuItem>
                    {sports?.map((val) => {
                      const { id, center_name } = val;
                      return (
                        <MenuItem value={JSON.stringify(val)} key={id}>
                          <Checkbox checked={formik.values.sports_center.indexOf(JSON.stringify(val)) > -1} />
                          {center_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.touched.sports_center &&
                    formik.errors.sports_center && (
                      <FormHelperText className="Mui-error">
                        {formik.errors.sports_center}
                      </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Location
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                      formik.touched.location && formik.errors.location
                    )}
                    margin="normal"
                    required
                    value={formik.values.location}
                    fullWidth
                    location="location"
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="location"
                    autoComplete="location"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    id="speciallsation"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Specialization
                  </InputLabel>
                  <Select
                    onBlur={formik.handleBlur}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => { formik.handleChange(e) }}
                    value={formik.values.speciallsation}
                    required
                    style={{ marginTop: "13px" }}
                    name="speciallsation"
                    helperText={
                      formik.touched.speciallsation &&
                      formik.errors.speciallsation
                    }
                    error={Boolean(
                      formik.touched.speciallsation &&
                      formik.errors.speciallsation
                    )}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>
                        --- Select Specialization ---
                      </em>
                    </MenuItem>
                    <MenuItem value="Cardio">Cardio</MenuItem>
                    <MenuItem value="Strength">Strength</MenuItem>
                  </Select>
                  {formik.touched.speciallsation && formik.errors.speciallsation && (
                    <FormHelperText className="Mui-error">
                      {formik.errors.speciallsation}
                    </FormHelperText>
                  )}
                </Grid>
                {param.id === 'add' ? <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Password
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                    margin="normal"
                    required
                    fullWidth
                    location="password"
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    onBlur={formik.handleBlur}
                    // onKeyUp={}
                    onChange={(e) => { formik.handleChange(e) }}
                    name="password"
                    type="password"
                    autoComplete="password"
                    variant="outlined"
                  />
                </Grid> : null}
                {param.id !== 'add' ? <Grid item sm={12} md={12}>
                  <Box className='swtichBox'>
                    <label>Activate</label>
                    <Switch checked={status} onChange={onChangeStatus}/>
                    <label>Deactivate</label>
                  </Box>
                </Grid>:null}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "180px",
                      padding: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      //   disabled={isSubmitting}
                      type="submit"
                      style={{
                        backgroundColor: "#232b58",
                        color: "#fff",
                        borderRadius: "25px",
                        width: "170Px",
                        padding: "13px",
                        fontSize: "17px",
                      }}
                      className="btn-submit"

                    >
                      {isLoading ? <CircularProgress Shrink /> : param.id === 'add' ? "Submit" : "Update"}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </AppLayout>
  );
};
export default AddCoach;
