import React from "react";
import { IconButton, Button, TableRow, TableHead, TableCell, TableBody, Table, Container, Grid, List, ListItem, CircularProgress, Box, TextareaAutosize } from "@material-ui/core";
import { Delete, Settings } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { DialogActions, Dialog, DialogContent, DialogContentText, DialogTitle, TableContainer, InputLabel } from "@mui/material";
import Pagination from "../../component/Page/Pagination";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { Done, Close, QueryBuilder, Visibility } from '@mui/icons-material';
import TypeSearch from "../../component/search";
import moment from 'moment'
export default function CoachSportscenter() {
    const [dataPending, setDataPending] = useState({});
    const [dataAssign, setDataAssign] = useState({});
    const [setid, SetsetId] = useState({
        id: null,
        verify: null,
        message: '',
        type: ''
    });
    const [tab, setTab] = useState(1)
    const [searchTerm, setsearchTerm] = useState("");
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(1)
    const handleClickOpen = (id, val, type) => {
        const param = {
            ...setid,
            id: id,
            verify: val,
            type: type
        }
        if (!val) {
            setOpen(!open);
            SetsetId(param);
        } else {
            deleteUser(param)
        }
    };
    useEffect(() => {
        document.title = "Centre";
        loadUsersAssign();
    }, []);
    const loadUsers = (page, search) => {
        setLoader(true)
        Fetch(`sports/coach-assign/pending-sport-center-list/${page ? '?page=' + page : ''}${search ? '&search=' + search : ''}`).then(res => {
            setLoader(false)
            if (res.status) {
                setPage(page)
                setDataPending(res.data);
            }
        })
    };
    const loadUsersAssign = (page, search) => {
        setLoader(true)
        Fetch(`sports/coach-assign/${page ? '?page=' + page : ''}${search ? '&search=' + search : ''}`).then(res => {
            setLoader(false)
            if (res.status) {
                setPage(page)
                setDataAssign(res.data);
            }
        })
    }
    const handleTab = (val) => {
        setTab(val)
        setsearchTerm('')
        if (val === 1) {
            loadUsersAssign(1)
        } else if (val === 2) {
            loadUsers(1)
        }
    }
    const deleteUser = (setid) => {
        let val = setid?.type === 'assign' ? null:setid
        Fetch(`sports/coach-assign/${setid?.type !== 'assign' ? 'verify-sport-center/' : setid.id+`/?message=${setid.message}`}`, val, { method: setid?.type !== 'assign'?'post':'delete' }).then(res => {
            if (res.status) {
                if (tab === 1) {
                    loadUsersAssign(page)
                } else if (tab === 2) {
                    loadUsers(page)
                }
                handleClickOpen(null)
                setOpen(false);
                toast.success(setid?.type === 'assign' ? `You've left Center successfully`:setid?.verify ? "Thanks for accepting the request." : 'Request has been rejected.', {
                    hideProgressBar: false,
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        })
    }
    const handleChange = (e) => {
        SetsetId({
            ...setid,
            message: e.target.value
        });
    }
    const data = tab === 1 ? dataAssign : dataPending
    return <>
        <ToastContainer />
        <Container maxWidth="lg">
            <Grid container spacing={3} alignItems='center' className="owners py-5">
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    <h3
                        className="topheading
            "
                    >
                        {" "}
                        Centre
                    </h3>
                </Grid>
                <Grid
                    className="buttons headSearch"
                    item
                    xs={12}
                    sm={6}
                >
                    <TypeSearch searchVal={searchTerm} handleChange={(val) => tab === 1 ? loadUsersAssign(1, val) : loadUsers(1, val)} />
                </Grid>
            </Grid>
        </Container>
        <Container>
            <div className="WidgetLg" style={{ marginBottom: "85px" }}>
                <TabList handleTab={handleTab} tab={tab} />
                <TableCenter loader={loader} page={page} data={data} tab={tab} handleClickOpen={handleClickOpen} searchTerm={searchTerm} />
                {data?.results?.length ? <Pagination data={data} sendPageNumber={loadUsers} /> : null}
            </div>
            <Dialog open={open} onClose={() => handleClickOpen(null)}>
                <DialogTitle>{tab === 1 ? 'Leave Centre ' : 'Reject Centre Request'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to{tab === 1 ? ' leave this Centre' : ' reject this request'}?
                        <InputLabel
                            className="Input"
                            style={{
                                color: "rgba(12,11,69,255)",
                                display: "flex",
                                fontSize: "15px",
                                marginTop: 15,
                                fontWeight: "bold",
                            }}
                        >
                            Reason
                        </InputLabel>
                        <TextareaAutosize
                            minRows={4}
                            margin="normal"
                            className="textare-input"
                            placeholder="Reason"
                            required
                            fullWidth
                            onChange={handleChange}
                            name={`Reason`}
                            type="text"
                            variant="outlined"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="Buttonss">
                    <Button onClick={() => handleClickOpen(null)}>No</Button>
                    <Button onClick={() => deleteUser(setid)}>Yes</Button>
                </DialogActions>
            </Dialog>
        </Container>
    </>
}
const TableCenter = ({ data, handleClickOpen, loader, page = 1, tab }) => {
    const [list, setList] = useState({})
    const [open, setOpen] = useState(false)
    const handleClose = (val) => {
        setList(val)
        setOpen(!open)
    }
    const handleClick = (val) => {
        setOpen(!open)
    }
    return <TableContainer>
        <Table aria-label="customized table">
            <TableHead>
                <TableRow>
                    <TableCell align="center"> S.No.</TableCell>
                    <TableCell align="left">Centre</TableCell>
                    <TableCell align="left"> Location </TableCell>
                    <TableCell align="left"> Contact </TableCell>
                    <TableCell align="center"> Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    loader ?
                        <TableRow>
                            <TableCell colSpan={6} align="center"><CircularProgress /></TableCell>
                        </TableRow>
                        :
                        data?.results?.length ?
                            data?.results?.map((admin, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                                        <TableCell align="left">
                                            {admin?.center_name}
                                        </TableCell>
                                        <TableCell align="left">{admin?.location}</TableCell>
                                        <TableCell align="left">
                                            {admin?.phone_no}
                                        </TableCell>
                                        <TableCell align="center">
                                            <div className="flex align-center justify-center">
                                                <IconButton
                                                    variant="contained"
                                                    title="View"
                                                    type="button"
                                                    onClick={() => handleClose(admin)}
                                                    style={{
                                                        padding: "0",
                                                        boxShadow: "none",
                                                        border: "none",
                                                        background: "none",
                                                        minWidth: "0px",
                                                        marginRight: '15px'
                                                    }}
                                                >
                                                    <Visibility
                                                        style={{ color: "#454545", margin: "-5px" }}
                                                    />
                                                </IconButton>
                                                {
                                                    tab === 1 ?
                                                        <Link to={`/sportscentersetting/${admin?.id}`}>
                                                            <IconButton
                                                                variant="contained"
                                                                type="button"
                                                                title="Setting"
                                                                style={{
                                                                    padding: "0",
                                                                    boxShadow: "none",
                                                                    border: "none",
                                                                    background: "none",
                                                                    minWidth: "0px",
                                                                }}
                                                            >
                                                                <Settings
                                                                    style={{ color: "#17a248", margin: "-5px" }}
                                                                />
                                                            </IconButton>
                                                        </Link>
                                                        :
                                                        <IconButton
                                                            onClick={() => handleClickOpen(admin?.id, true)}
                                                            variant="contained"
                                                            title="Accept"
                                                            type="button"
                                                            style={{
                                                                padding: "0",
                                                                boxShadow: "none",
                                                                border: "none",
                                                                background: "none",
                                                                minWidth: "0px",
                                                            }}
                                                        >
                                                            <Done
                                                                style={{ color: "#17a248", margin: "-5px" }}
                                                            />
                                                        </IconButton>
                                                }
                                                <IconButton
                                                    onClick={() => handleClickOpen(admin?.id, false, tab === 1 ? 'assign' : '')}
                                                    title={tab === 1 ? "Leave Centre" : "Reject"}
                                                    style={{
                                                        padding: "0",
                                                        boxShadow: "none",
                                                        border: "none",
                                                        background: "none",
                                                        minWidth: "0px",
                                                    }}
                                                >
                                                    {
                                                        tab === 1 ?
                                                            <Delete style={{ color: "red", margin: "8px" }} />
                                                            :
                                                            <Close style={{ color: "red", margin: "8px" }} />
                                                    }
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            :
                            <TableRow>
                                <TableCell colSpan={6} align="center">No Data Found</TableCell>
                            </TableRow>
                }
            </TableBody>
        </Table>
        <Dialog className='smDialog' open={open} onClose={handleClick}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <DialogTitle sx={{
                    fontSize: '16px !important',
                    fontWeight: 600,
                }}>Centre Detail</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClick}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </Box>
            <DialogContent dividers>
                <DialogContentText>
                    <ul className="list-view">
                        <li><strong>Centre:</strong> {list?.center_name || 'N/A'}</li>
                        <li><strong>Location:</strong> {list?.location || 'N/A'}</li>
                        <li><strong>Contact:</strong> {list?.phone_no || 'N/A'}</li>
                        <li><strong>Timings:</strong> {list?.timefrom ? moment(list?.timefrom, "HH:mm:ss").format("hh:mm A") : 'N/A'} - {list?.timeto ? moment(list?.timeto, "HH:mm:ss").format("hh:mm A") : 'N/A'}</li>
                        <li><strong>Email:</strong> {list?.email || 'N/A'}</li>
                        <li><strong>Owner Name:</strong> {list?.owner_name || 'N/A'}</li>
                    </ul>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    </TableContainer>
}
const TabList = ({ tab, handleTab }) => {
    return <List className="table-tabs">
        <ListItem><Button className={tab === 1 ? 'active' : ''} onClick={() => handleTab(1)}><Done /> Assigned Centre</Button></ListItem>
        <ListItem><Button className={tab === 2 ? 'active' : ''} onClick={() => handleTab(2)}><QueryBuilder /> Pending Requests</Button></ListItem>
    </List>
}