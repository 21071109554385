import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Paper, Grid, TextField, InputLabel, Container, MenuItem, Select, } from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import AppLayout from "../../layout/appLayout";
import Fetch from "../../common/fetch";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import Back from "../../component/backIco";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  filename: {
    fontSize: '12px',
    padding: 0,
    paddingRight: '10px',
    '& svg': {
      width: '20px',
      height: '20px'
    },
    '& button': {
      padding: 0,
      paddingLeft: '10px',
      minWidth: 'auto',
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const SportsCenterDetail = () => {
  let navigate = useNavigate();
  const classes = useStyles();
  const param = useParams()
  const [state, setState] = useState({
    location: "",
    owner: "",
    phone_no: "",
    center_name: "",
    gender: '',
    timings: "",
  });
  useEffect(() => {
    loadUser()
  }, [param])
  const loadUser = () => {
    Fetch(`sports/sport-center-listing/${param.id}/`).then(res => {
      if (res.status) {
        const { data } = res
        const time = moment(data?.timefrom, "HH:mm:ss").format("hh:mm A") + ' - ' + moment(data?.timeto, "HH:mm:ss").format("hh:mm A")
        setState({
          location: data.location,
          owner: data.owner_name,
          phone_no: data.phone_no,
          center_name: data.center_name,
          gender: data.gender || '',
          timings:time || '' ,
        })
      }
    })
  }
  return (
    <AppLayout style={{ marginBottom: "50px" }}>
      <ToastContainer />
      <Container>
        <h3 className="flex align-center" style={{ padding: "10px 10px 10px 0px" }}><Back/>Centre Detail</h3>
        <Paper elevation={3}>
          <div
            className={classes.root}
            style={{ padding: "20px", marginBottom: "80px" }}
          >
            <form method="POST" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    id="center_name"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Centre Name
                  </InputLabel>
                  <TextField
                    id="sport_center"
                    disabled
                    style={{ marginTop: "16px" }}
                    fullWidthe="center_name"
                    fullWidth
                    value={state.center_name}
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Owner
                  </InputLabel>
                  <TextField
                    disabled
                    fullWidth
                    margin="normal"
                    type="owner"
                    autoComplete="off"
                    value={state.owner}
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item sm={12} md={4}>
                  <InputLabel
                    id="speciallsation"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    fullWidth
                    variant="outlined"
                    value={state.gender}
                    required
                    style={{ marginTop: "16px" }}
                    name="service"
                    disabled
                  >
                    <MenuItem disabled value="">
                      <em>
                        --- Select ---
                      </em>
                    </MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </Grid> */}
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Location
                  </InputLabel>
                  <TextField
                    margin="normal"
                    required
                    disabled
                    value={state.location}
                    fullWidth
                    name="location"
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Timings
                  </InputLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    disabled
                    value={state.timings}
                    name="timings"
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Phone No.
                  </InputLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={state.phone_no}
                    disabled
                    autoComplete="off"
                    variant="outlined"
                    type="tel"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ textAlign: "center", marginTop: "100px" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{
                        backgroundColor: "#232b58",
                        textTransform: 'uppercase',
                        color: "#fff",
                        borderRadius: "25px",
                        width: "290Px",
                        padding: "13px",
                        fontSize: "17px",
                      }}
                      onClick={(e) => navigate('/subscription-plans')}
                      className="btn-submit"
                    >
                      Purchase Subscription
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </AppLayout>
  );
};
export default SportsCenterDetail;