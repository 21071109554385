import { Outlet, Navigate, Routes, } from "react-router-dom";
import Sportscenterowner from "../../pages/Sportscenterowner/Sportscenterowner";
import Login from "../../pages/login"
export const PrivateRoutes = ({ children, ...rest }) => {
    let auth = localStorage.token
    return (
        auth?.length ? <Outlet /> : <Navigate to="/" element={<Login />} />
    )
}
export const GuestRoutes = ({ children, ...rest }) => {
    let auth = localStorage.token
    let url = localStorage.role !== 'Admin' ? '/centre' : '/centreowner'
    return (
        !auth?.length ? <Outlet /> : <Navigate to={url} element={<Sportscenterowner />} />
    )
}

// export default PrivateRoutes;