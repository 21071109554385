/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import { TableRow, TableHead, TableBody, TableCell, IconButton, Container, Grid, Button, Box, CircularProgress } from "@material-ui/core";
import { Delete, Edit, AddCircleOutlineOutlined } from "@material-ui/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, TableContainer, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";
import "./category.css";
import swal from "sweetalert2";
import Pagination from "../../component/Page/Pagination";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { arrayString, paginationSize } from "../../common/utilits";
import TypeSearch from "../../component/search";

export default function WidgetLg() {
  const [searchTerm, setsearchTerm] = useState("");
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);
  const [setid, SetsetId] = useState();
  const [loader, setLoader] = useState(true)
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1)

  const handleClickOpen = (id) => {
    setOpen(true);
    SetsetId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = (page, search) => {
    Fetch(`sports/categories/${page ? '?page=' + page : ''}${search ? '&search=' + search : ''}`).then(res => {
      setLoader(false)
      if (res?.status) {
        setPage(page ? page : 1)
        setData(res.data);
      }
    })
  };
  const deleteUser = () => {
    Fetch(`sports/categories/${setid}/`, null, { method: 'delete' }).then(res => {
      if (res?.status) {
        setOpen(false);
        loadUsers(page);
        toast.success("Category Deleted Successfully.", {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        let resErr = arrayString(res)
        toast.error(resErr.error, {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  };
  return (
    <>
      <ToastContainer />
      <Container>
        <Grid container spacing={3} alignItems='center' className="categorymg py-5">
          <Grid
            item
            xs={12}
            sm={6}
          >
            <h3> Categories</h3>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className="butt category headSearch"
          >
            <TypeSearch handleChange={(val) => loadUsers(1, val)} />
            <Link
              to="/category/add"
              style={{ textDecoration: "none" }}
            >
              <Button
                className="add-btn"
                variant="contained"
                type="submit"
              >
                <AddCircleOutlineOutlined style={{ marginRight: "6px" }} />
                Add Category
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <div className="WidgetLg" style={{ marginBottom: "85px" }}>
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"> S.No.</TableCell>
                  <TableCell align="left"> Category</TableCell>
                  <TableCell align="left"> Price</TableCell>
                  <TableCell align="left">Centre</TableCell>
                  <TableCell align="left">Division</TableCell>
                  <TableCell align="center"> Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  loader ?
                    <TableRow>
                      <TableCell colSpan={6} align="center"><CircularProgress /></TableCell>
                    </TableRow>
                    :
                    data?.results?.length ? data?.results?.filter((val) => {
                      if (searchTerm === "") {
                        return val;
                      } else if (
                        val.category
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        val.location
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        val.sport.toLowerCase().includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                      .map((admin, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell align="center">{(page - 1) * paginationSize + i + 1}</TableCell>
                            <TableCell align="left" width={200}>{admin?.category}</TableCell>
                            <TableCell align="left">{admin?.price} INR/Monthly</TableCell>
                            <TableCell align="left">
                              {admin?.sport_center?.center_name}
                            </TableCell>
                            <TableCell align="left">{admin.sport?.title}</TableCell>
                            <TableCell align="center">
                              <Box className="flex align-center justify-center">
                                <Link to={`/category/${admin.id}`}>
                                  <IconButton
                                    variant="contained"
                                    title="Edit"
                                    type="submit"
                                    style={{
                                      padding: "0",
                                      boxShadow: "none",
                                      border: "none",
                                      background: "none",
                                      minWidth: "0px",
                                    }}
                                  >
                                    {" "}
                                    <Edit style={{ color: "#17a248" }} />
                                  </IconButton>
                                </Link>
                                <IconButton
                                  title="Delete"
                                  style={{
                                    padding: "0",
                                    boxShadow: "none",
                                    border: "none",
                                    background: "none",
                                    minWidth: "0px",
                                  }}
                                >
                                  <Delete
                                    //onClick={handleClickOpen}
                                    onClick={() => handleClickOpen(admin.id)}
                                    style={{ color: "red", margin: "7px" }}
                                  />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })
                      :
                      <TableRow>
                        <TableCell colSpan={6} align="center">No Data Found</TableCell>
                      </TableRow>
                }
              </TableBody>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Delete Category</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this Category?
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="Buttonss">
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={() => deleteUser()}>OK</Button>
                </DialogActions>
              </Dialog>
            </Table>
          </TableContainer>
          {data?.results?.length ? <Pagination page={page} data={data} sendPageNumber={loadUsers} /> : null}
        </div>
      </Container>
    </>
  );
}
