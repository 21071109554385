import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, FormHelperText, Paper, Grid, TextField, InputLabel, Container, MenuItem, Select, Box } from "@material-ui/core";
import { useEffect } from "react";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert2";
import AppLayout from "../../layout/appLayout";
import CircularProgress from "@mui/material/CircularProgress";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { arrayString, onKeyPress } from "../../common/utilits";
import Back from "../../component/backIco";
import { Autocomplete, Chip, Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const validationSchema = yup.object({
  category: yup.string().required("Category is required."),
  sport_center: yup.string().required("Centre is required."),
  price: yup.string().required("Price is required."),
  sport: yup
    .string()
    .required("Division is required."),
});

export default function Addcategory() {
  const classes = useStyles();
  let navigate = useNavigate();
  const param = useParams()
  const [sportsCenter, setSportsCenter] = useState([]);
  const [sports, setSports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    category: "",
    sport: "",
    sport_center: "",
    price: ''
  })
  useEffect(() => {
    document.title = "Add Category";
    handleSportsCenter();
    loadSports();
    if (param.id !== 'add') {
      loadUser(param.id)
    }
  }, [param]);
  const loadSports = () => {
    Fetch(`sports/sports`).then(res => {
      if (res.status) {
        const { data } = res
        setSports(data)
      }
    })
  }
  const loadUser = (id) => {
    Fetch(`sports/categories/${id}/`).then(res => {
      if (res.status) {
        const { data } = res
        let val = {
          ...data?.sport
        }
        delete val.slug
        setState({
          category: data?.category,
          sport: JSON.stringify(val),
          sport_center: data?.sport_center?.id,
          price: data?.price || ''
        });
      }
    })
  };
  const onSubmit = () => {
    if (formik.isValid) {
      setIsLoading(true);
      let params ={
        ...formik.values,
        sport:JSON.parse(formik.values?.sport)?.id
      }
      Fetch(`sports/categories/${param.id === 'add' ? '' : param.id + '/'}`, params, { method: param.id === 'add' ? 'post' : 'patch' }).then(res => {
        setIsLoading(false);
        if (res?.status) {
          toast.success(`Category ${param.id === 'add' ? 'Added' : 'Updated'} Succesfully.`, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/categorymanagement/");
          }, 3000)
        } else {
          let resErr = arrayString(res)
          toast.error(resErr.error, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
    }
  };
  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const handleSportsCenter = () => {
    Fetch("sports/sports-center/sports-center-owner/").then(res => {
      if (res.status) {
        setSportsCenter(res.data);
      }
    })
  };
  return (
    <AppLayout>
      <ToastContainer />
      <Container>
        <h3 className="flex align-center" style={{ padding: "10px 10px 10px 0px" }}><Back /> {param.id === 'add' ? 'Add New' : 'Edit'} Category</h3>
        <Paper elevation={3}>
          <div className={classes.root} style={{ padding: "20px" }}>
            <form method="POST" noValidate onSubmit={formik.handleSubmit}>
              {/* <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                  <Box>
                    <Stack direction="row" spacing={1}>
                      {
                        state.category?.map((items,key)=><Chip
                        label={items}
                        key={key}
                        onDelete={() => onDelete()}
                      />)
                      }
                      
                    </Stack>
                  </Box>
                </Grid>
                </Grid> */}
              <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                  <InputLabel
                    id="sport"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Division
                  </InputLabel>
                  <Select
                    error={Boolean(formik.touched.sport && formik.errors.sport)}
                    fullWidth
                    helperText={formik.touched.sport && formik.errors.sport}
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                    name="sport"
                    value={formik.values.sport}
                    onChange={(e) => {
                      const { value } = e.target
                      formik.handleChange(e);
                      formik.setFieldValue('price', JSON.parse(value)?.base_price || '')
                    }}
                    defaultValue="Select"
                    displayEmpty
                    style={{ marginTop: "16px" }}
                    className='popover'
                  >
                    <MenuItem disabled value="">
                      <em>---Select Division---</em>
                    </MenuItem>
                    {
                      sports?.map(item => <MenuItem value={JSON.stringify(item)}>{item.title}</MenuItem>)
                    }
                  </Select>
                  {formik.touched.sport && formik.errors.sport && (
                    <FormHelperText className="Mui-error">
                      {formik.errors.sport}
                    </FormHelperText>
                  )}
                </Grid>
                {formik.values.sport ? <Grid item sm={12} md={6}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Price (INR/Monthly)
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                      formik.touched.price && formik.errors.price
                    )}
                    fullWidth
                    helperText={
                      formik.touched.price && formik.errors.price
                    }
                    value={formik.values.price}
                    onKeyPress={onKeyPress}
                    margin="normal"
                    autoComplete="name"
                    name="price"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) => { formik.handleChange(e) }}
                  />
                </Grid> : null}
                <Grid item sm={12} md={6}>
                  <InputLabel
                    id="sport_center"
                    name="sport_center"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Centre
                  </InputLabel>
                  <Select
                    inputProps={{ maxLength: 30 }}
                    error={Boolean(
                      formik.touched.sport_center &&
                      formik.errors.sport_center
                    )}
                    style={{
                      marginTop: "16px",
                    }}
                    margin="normal"
                    required
                    fullWidth
                    name="sport_center"
                    variant="outlined"
                    displayEmpty
                    helperText={
                      formik.touched.sport_center &&
                      formik.errors.sport_center
                    }
                    onKeyUp={formik.dirty}
                    onBlur={formik.handleBlur}
                    onChange={(e) => { formik.handleChange(e) }}
                    value={formik.values.sport_center}
                  >
                    <MenuItem disabled value="">
                      <em>---Select Centre---</em>
                    </MenuItem>
                    {sportsCenter?.map((val) => {
                      const { id, center_name } = val;
                      return (
                        <MenuItem value={id} key={id}>
                          {center_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.touched.sport_center &&
                    formik.errors.sport_center && (
                      <FormHelperText className="Mui-error">
                        {formik.errors.sport_center}
                      </FormHelperText>
                    )}
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Category
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    // onKeyDown={(e) => {
                    //   if (e.key === "Enter" && !state.category?.includes(e.target.value)) {
                    //     let data = [...state.category, e.target.value]
                    //     setState({
                    //       ...state,
                    //       category: data
                    //     })
                    //   }
                    // }}
                    error={Boolean(
                      formik.touched.category && formik.errors.category
                    )}
                    fullWidth
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                    required
                    value={formik.values.category}
                    margin="normal"
                    // category="category"
                    // autoComplete="name"
                    name="category"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) => { formik.handleChange(e) }}
                  />
                </Grid>

              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={12} md={12}>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "180px",
                      padding: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      //   disabled={isSubmitting}
                      type="submit"
                      style={{
                        backgroundColor: "#232b58",
                        color: "#fff",
                        borderRadius: "25px",
                        width: "165Px",
                        padding: "11px",
                        fontSize: "17px",
                      }}
                      className="btn-submit"

                    >
                      {isLoading ? <CircularProgress Shrink /> : param.id !== 'add' ? "Update" : "Submit"}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </AppLayout>
  );
}
