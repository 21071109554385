import Footer from "../component/Page/Header/Footer";
import Header from "../component/Page/Header/Header";
import { Grid, ListItem, Toolbar, AppBar, ListItemText, makeStyles, Drawer, Box, useMediaQuery, IconButton, Avatar } from "@material-ui/core";
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import SportsIcon from '@mui/icons-material/Sports';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CategoryIcon from '@mui/icons-material/Category';
import "./AppLayout.css";
import {
    SettingsOutlined,
} from "@material-ui/icons";
import LoadingBar from "react-top-loading-bar";
import {
    Link,
    NavLink, useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { menuList } from "../json/menu";
import { withContext } from "../context/appContext";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
}));

function AppLayout({ children, context }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const { role } = context
    const [name, setName] = useState('')
    const [progress, setProgress] = useState(0);
    const [menu, setMenu] = useState(false)
    const handleMenu = () => {
        setMenu(!menu)
    }
    const signOut = async () => {
        await localStorage.clear();
        await navigate("/");
    };
    useEffect(() => {
        setName(localStorage.name)
    }, [localStorage.name])
    return <>
        <Header handleMobileDrawerOpen={handleMenu} />
        <LoadingBar
            color="#f11946"
            timeout={10000}
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
        />
        <div className={classes.root}>
            <AppBar
                position="static"
                className={classes.sectionDesktop}
                style={{
                    backgroundColor: "#F9FBFD",
                    color: "black",
                }}
            >
                <Toolbar>
                    <List setProgress={setProgress} role={role} signOut={signOut} />
                </Toolbar>
            </AppBar>
            <Drawer
                open={menu}
                onClose={handleMenu}
            >
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={handleMenu}
                    onKeyDown={handleMenu}
                >
                    <IconButton
                        edge="end"
                        style={{ paddingBottom: 20,paddingTop:0,justifyContent:'flex-start',paddingLeft:'15px' }}
                        aria-label="account of current user"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <Avatar />
                        <p style={{ marginLeft: "10px", fontSize: "15px" }}>
                            <div className="flex align-center">
                                {name === "null" ? 'Super Admin' : name}
                            </div>
                            <p className="role-name">{role}</p>
                        </p>

                    </IconButton>
                    <List setProgress={setProgress} role={role} signOut={signOut} />
                </Box>
            </Drawer>
            <div className="main">
                {children}
            </div>
            <Footer />
        </div>
    </>
}
const List = ({ setProgress, role, signOut }) => {
    const matches = useMediaQuery('(max-width:959px)');
    return <Grid container spacing={2} justifyContent={matches ? "flex-start" : "center"}>
        {
            menuList?.map((items, key) => {
                return items.role.includes(role) ? <Grid item style={{ alignSelf: "center" }} key={key}>
                    <NavLink
                        className="id button"
                        onClick={() => setProgress(100)}
                        to={items.url}
                        style={{ textDecoration: "none" }}
                    >
                        <ListItem button key={"sports-center-owners"}>
                            <img src={items.icon} width='20px' height='20px' />
                            {/* <DomainAddIcon style={{}} /> */}
                            <ListItemText
                                primary={items?.name}
                                style={{

                                    fontSize: "20px",
                                    marginLeft: "6px",
                                }}
                            />
                        </ListItem>
                    </NavLink>
                </Grid> : null
            })
        }
        {matches ? <Grid item style={{ alignSelf: "center" }}>
            <Link to='/' className="id button">
                <ListItem button key={"sports-center-owners"} onClick={signOut}>
                    <img src="/icon/menu/log-out.png" width='20px' height='20px' />
                    <ListItemText
                        primary='Log Out' />
                </ListItem>
            </Link>
        </Grid> : null}
    </Grid>
}
export default withContext(AppLayout)