import { React, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, TextField, InputLabel, Container, Button, FormHelperText, MenuItem, Select, Box, Switch } from "@material-ui/core";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import AppLayout from "../../layout/appLayout";
import CircularProgress from "@mui/material/CircularProgress";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { arrayString } from "../../common/utilits";
import Back from "../../component/backIco";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
const emailRegx = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
let validateError = {
  name: yup
    .string()
    .max(25, "Must be 25 characters or less")
    .matches(/^[A-Za-z ]*$/, "Only alphabets are required.")
    .required("Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .matches(emailRegx, "Invalid Email ID...")
    .required("Email is required."),
  phone_no: yup
    .string()
    .min(10, "Phone number should not be less than 10 digits.")
    .max(10, "Phone number should not be more than 10 digits.")
    .required("Phone number is required.")
    .matches(phoneRegExp, "Phone number must contains only digits."),
  location: yup
    .string()
    .max(50, "Must be 50 characters or less.")
    .required("Location is required."),
  gender: yup.string().required("Gender is required."),
}
export default function AddUser() {
  let navigate = useNavigate();
  const param = useParams()
  const classes = useStyles();
  validateError = param.id === 'add' ? {
    ...validateError, password: yup
      .string()
      .matches(PASSWORD_REGEX, " Password must have at least 8 characters and the combination of the following: uppercase letter, lowercase letter, numbers, and symbols.")
      .required("Password is required."),
  } : validateError
  const validationSchema = yup.object().shape(validateError);
  useEffect(() => {
    document.title = "Add User";
    if (param.id !== 'add') {
      loadUser(param.id)
    }
  }, [param]);
  const [status,setStatus]=useState(false)
  const [error, seterror] = useState();
  const [state, setState] = useState({
    name: "",
    email: "",
    phone_no: "",
    location: "",
    gender: "",
  })
  const [isLoading, setIsLoading] = useState(false);
  const loadUser = (id) => {
    Fetch(`sports/user/${id}/`).then(res => {
      if (res.status) {
        const { data } = res
        setStatus(data?.status)
        setState({
          name: data?.name || '',
          email: data?.email || '',
          phone_no: data?.phone_no || '',
          location: data?.location || '',
          gender: data?.gender || '',
        });
      }
    })
  };
  const onSubmit = () => {
    if (formik.isValid) {
      setIsLoading(true);
      let val = { ...formik.values }
      if (param.id !== 'add') {
        delete val.email
      }
      Fetch(`sports/user/${param.id === 'add' ? '' : param.id + '/'}`, val, { method: param.id === 'add' ? 'post' : 'patch' }).then(res => {
      setIsLoading(false);
        if (res.status) {
          toast.success(`User ${param.id === 'add' ? 'added' : 'updated'} successfully.`, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/usermanagement/");
          }, 3000)
        } else {
          let resErr = arrayString(res)
          toast.error(resErr.error, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
    };
  }
  const initialValues = param.id === 'add' ? {
    ...state,
    password: "",
  } : state
  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  const onChangeStatus=(e)=>{
    setStatus(e.target.checked)
    Fetch('users/deactivate-user/',{user_id:Number(param.id),role:'User'},{method:'post'}).then(res=>{
      if(res.status){
        toast.success(`User has been ${e.target.checked?'Activated':'Deactivated'} Successfully.`, {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  }
  return (
    <AppLayout>
      <ToastContainer />
      <Container>
        <h3 className="flex align-center" style={{ padding: "10px 10px 10px 0px" }}><Back/> {param.id === 'add' ?'Add New':'Edit'} User</h3>
        <Paper elevation={3}>
          <div className={classes.root} style={{ padding: "20px" }}>
            <form
              method="POST"
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    margin="normal"
                    required
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    name="name"
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    type="text"
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Location
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                      formik.touched.location && formik.errors.location
                    )}
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                    margin="normal"
                    required
                    fullWidth
                    value={formik.values.location}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="location"
                    type="text"
                    variant="outlined"
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel
                    id="gender"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                      padding: "8px",
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    error={Boolean(
                      formik.touched.gender && formik.errors.gender
                    )}
                    helperText={formik.touched.gender && formik.errors.gender}
                    fullWidth
                    id="gender"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    value={formik.values.gender}
                    onChange={(e) => { formik.handleChange(e); }}
                    name="gender"
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>--- Select Gender ---</em>
                    </MenuItem>
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                  {formik.touched.gender &&
                    formik.errors.gender && (
                      <FormHelperText className="Mui-error">
                        {formik.errors.gender}
                      </FormHelperText>
                    )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Email
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    disabled={param.id !== 'add'}
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    margin="normal"
                    value={formik.values.email}
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    variant="outlined"
                  />
                  <p style={{ color: "red", margin: "0px", fontSize: "12px" }}>
                    {error}
                  </p>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Phone Number
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 13 }}
                    error={Boolean(
                      formik.touched.phone_no && formik.errors.phone_no
                    )}
                    margin="normal"
                    required
                    fullWidth
                    helperText={
                      formik.touched.phone_no && formik.errors.phone_no
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.phone_no}
                    onChange={formik.handleChange}
                    name="phone_no"
                    type="tel"
                    variant="outlined"
                  />
                </Grid>
                {param.id !== 'add' ? <Grid item sm={12} md={12}>
                  <Box className='swtichBox'>
                    <label>Activate</label>
                    <Switch checked={status} onChange={onChangeStatus}/>
                    <label>Deactivate</label>
                  </Box>
                </Grid>:null}
                {param.id === 'add' ? <Grid item sm={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Password
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                    margin="normal"
                    required
                    fullWidth
                    location="password"
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="password"
                    type="password"
                    autoComplete="password"
                    variant="outlined"
                  />
                </Grid> : null}
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={12} md={12}>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "180px",
                      padding: "20px",
                    }}
                  >

                    <Button
                      variant="contained"
                      //   disabled={isSubmitting}
                      type="submit"
                      // onClick={(e) => {
                      //   onSubmit(e);
                      // }}
                      style={{
                        backgroundColor: "#232b58",
                        color: "#fff",
                        borderRadius: "25px",
                        width: "143Px",
                        padding: "13px",
                        fontSize: "17px",
                      }}
                      className="btn-submit"

                    >
                      {isLoading === true ? (
                        <CircularProgress Shrink />
                      ) : (
                        param.id === 'add' ?"Submit":'Update'
                      )}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </AppLayout>
  );
}
