import { React, setState } from "react";
import { Typography, InputLabel, Container, Button, CssBaseline, TextField, Grid } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Forgetpassword.css";
import { VpnKeyOutlined } from "@material-ui/icons";
import { useParams,useHistory } from "react-router-dom";
import Fetch from "../../common/fetch";
import { ToastContainer, toast } from 'react-toastify';
import { arrayString } from "../../common/utilits";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },

  paper: {
    margin: theme.spacing(0, 5),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
const validationSchema = yup.object({
  password: yup
    .string()
    .matches(PASSWORD_REGEX, "Please enter a strong password")
    .required(),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Password does not match"),
    }),
});

export default function SignInSide() {
  const navigate = useNavigate()
  useEffect(() => {
    document.title = "Reset Password";
  }, []);
  const classes = useStyles();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const baseURL = process.env.REACT_APP_API_ENDPOINT;
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [password, setPassword] = useState();
  const handlePasswordonChange = (e) => {
    setPassword(e.target.value);
  };
  const onSubmit = async (e) => {
    // e.preventDefault();
    Fetch(`users/reset/change-password/${id}/`, {
      password2: formik.values.confirmPassword,
      password: password,
    }, { method: 'post' }).then(res => {
      setIsLoading(false);
        if (res.status) {
          toast.success("Password Reset Successfully", {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              navigate('/')
            },
          });
        } else {
          let resErr = arrayString(res)
          toast.error(resErr.error, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
    })
    // const res = await axios
    //   .post(baseURL + `users/reset/change-password/${id}/`, {
    //     password2: formik.values.confirmPassword,
    //     password: password,
    //   })
    //   .then((res) => {
    //     setMessage(res.data.success);
    //     toast.success("Password Changed Successfully", {
    //       hideProgressBar: false,
    //       position: "top-right",
    //       autoClose: 3000,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "colored",
    //     });
    //   })
    //   .catch((error) => {
    //     setMessage(error.message);
    //   });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <ToastContainer/>
      <Grid md={12}>
        <div className="bgimgforgot">
          <Container maxWidth="false">
            <div className="main">
              <Grid
                container
                spacing={3}
                className="secondforgot"
                style={{ margin: "0" }}
              >
                <Grid item xs={12} md={6}>
                  <div className="display" style={{ color: "white" }}>
                    <p style={{ fontSize: "20px" }}>WELCOME TO</p>
                    <Typography component="h1" variant="h5" className="Sig">
                      Centre <br></br>Management
                      <hr
                        style={{
                          width: "36%",
                          textAlign: "left",
                          marginLeft: "0",
                        }}
                      />
                    </Typography>
                    {/* <h4 style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      Lorem lpsum ddummycontent
                    </h4>
                    <p className="pp" style={{ color: "white" }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      <br />
                      Mauris ac ornare enim{" "}
                    </p> */}
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={classes.paper} style={{ marginTop: "90px" }}>
                    {message && (
                      <div style={{ fontSize: "25px" }}>{message}</div>
                    )}
                    <Typography component="h1" variant="h4" className="Sign">
                      Reset Password
                    </Typography>
                    <p className="p">Fill your new password.</p>
                    <form
                      method="POST"
                      noValidate
                      onSubmit={formik.handleSubmit}
                    >
                      <InputLabel
                        className="InputLabel"
                        style={{
                          color: "rgba(12,11,69,255)",
                          padding: "5px",
                          display: "flex",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        <VpnKeyOutlined className="icon" />
                        NEW PASSWORD
                      </InputLabel>
                      <TextField
                        inputProps={{ maxLength: 40 }}
                        error={Boolean(
                          formik.touched.password && formik.errors.password
                        )}
                        fullWidth
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        margin="normal"
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyUp={handlePasswordonChange}
                        type="password"
                        value={formik.values.password}
                      />
                      <InputLabel
                        className="InputLabel"
                        style={{
                          color: "rgba(12,11,69,255)",
                          padding: "5px",
                          display: "flex",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        <VpnKeyOutlined className="icon" />
                        CONFIRM NEW PASSWORD
                      </InputLabel>

                      <TextField
                        inputProps={{ maxLength: 40 }}
                        error={Boolean(
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                        )}
                        fullWidth
                        helperText={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                        }
                        margin="normal"
                        name="confirmPassword"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyUp={handlePasswordonChange}
                        type="password"
                        value={formik.values.confirmPassword}
                      />

                      <Grid container justify="center">
                        <Button
                          className="btn-submit"
                          style={{
                            backgroundColor: "rgba(12,11,69,255)",
                            color: "white",
                            borderRadius: " 28px 28px",
                            padding: "10px 45px",
                            fontSize: "21px",
                            marginTop: "134px",
                          }}
                          // disabled={isSubmitting}
                          type="submit"
                          // href="/Header"
                          variant="outlined"
                          size="medium"
                          color="primary"
                        // className={classes.margin}
                        >
                          Reset Password
                        </Button>
                      </Grid>
                      <Grid container className="link">
                        <Grid item container justify="center">
                          <Link
                            style={{
                              textDecoration: "none",
                              fontWeight: "bold",
                            }}
                            to="/"
                            variant="body2"
                          >
                            {"Sign In here!"}
                          </Link>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </Grid>
    </Grid>
  );
}
