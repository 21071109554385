import React, { Component, createContext } from 'react'
import Auth from '../common/auth'

const MainContext = createContext({});
class AppContext extends Component {
    state = {
        token: '',
        isLogin: false,
        authLoading:true,
        role:'',
        userProfile: {},
    }

    componentDidMount() {
        const auth = Auth.isAuthenticated()
        this.setState({
            isLogin: auth,
            authLoading:false,
            role:localStorage.role,
            token: Auth.getAsyncToken(),
        })
    }
    updateAuthenticated = (data) => {
        this.setState({
            userProfile: data,
            role:data.role
        })
    }
    
    render() {
        return (
            <MainContext.Provider
                value={{
                    ...this.state,
                    updateAuthenticated: this.updateAuthenticated,
                }}
            >
                {this.props.children}
            </MainContext.Provider>
        )
    }
}
export default AppContext;
export const withContext = (Components) => (props) => {
    return (
        <MainContext.Consumer>
            {(value) => <Components context={value} {...props} />}
        </MainContext.Consumer>
    )
}
