import React, { useState } from 'react'
import { Container, Grid, Paper, Button, Stack, Card, Autocomplete, useMediaQuery } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import AppLayout from '../../layout/appLayout';
import { dietPlanDays } from '../../json/menu';
import CardDays from './card';
import { useEffect } from 'react';
import Fetch from "../../common/fetch";
import CardContentList from './card';
import { Box, CircularProgress, InputLabel, TextField } from '@material-ui/core';
import moment from 'moment';
import { ClassNames } from '@emotion/react';
const useStyles = makeStyles((theme) => ({
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down("xs")]: {
            display: "block",
            marginBottom: 15
        },
    },
    container: {
        [theme.breakpoints.down("xs")]: {
            padding: '0 !important'
        },
    },
    Paper: {
        padding: "30px", marginBottom: "-3px", minHeight: 'calc(100vh - 230px)',
        [theme.breakpoints.down("xs")]: {
            padding: "20px",
        },
    },
    days: {
        boxShadow: 'unset',
        borderColor: '#a2a2a2 !important',
        color: '#717171 !important',
        fontSize: '13px !important',
        padding: '7px 22px !important',
        [theme.breakpoints.down("md")]: {
            minWidth: "100px !important",
        },
        '&:hover': {
            backgroundColor: '#242b58 !important',
            color: '#fff !important'
        },
        '&.active': {
            backgroundColor: '#242b58',
            color: '#fff !important',

            '&:hover': {
                backgroundColor: '#242b58',
                color: '#fff !important'
            },
        }
    }
}))
function DietPlan() {
    const matches = useMediaQuery('(max-width:600px)');
    const className = useStyles()
    const [tab, setTab] = useState(0)
    const [data, setData] = useState([])
    const [dataUser, setDataUser] = useState([])
    const [dataUserFilter, setDataUserFilter] = useState([])
    const [loader, setLoader] = useState(false)
    const [loaderCoach, setLoaderCoach] = useState(true)
    const [userValue, setuserValue] = useState(null)
    const [userSearchValue, setUserSearchValue] = useState(null)
    const handleTab = (e) => {
        setTab(Number(e.target.value))
    }
    useEffect(() => {
        Fetch('sports/user-list-diet-plan/').then(res => {
            setLoaderCoach(false)
            if (res.status) {
                let id = res.data?.length ? {
                    ...res.data[0],
                    label: res.data[0]?.coach_name,
                } : null
                delete id.coach_name
                if (id) {
                setLoader(true)
                    loadDiet(id.id)
                }
                setuserValue(id)
                setDataUserFilter(res.data)
                setDataUser(res.data)
            }else{
                setLoader(false)
            }
        })
        setTab(moment().day())
    }, [])
    const loadDiet = (id) => {
        Fetch(`sports/user-diet-plan/?coach=${id}`).then(res => {
            setLoader(false)
            if (res.status) {
                setData(res.data?.filter(items => items?.dietTime?.length))
            }
        })
    }
    const searchFilter = (e) => {
        const { value } = e.target
        setUserSearchValue(value)
        setDataUserFilter(dataUser.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())));
    }
    return (
        <AppLayout>
            <Container maxWidth="lg">
                <Box className={className.head}>
                    <Box style={{ marginTop: "30px", marginBottom: "20px" }}>
                        <h3> Plan </h3>
                    </Box>
                    {!loaderCoach &&
                        <div className='flex align-center'>
                            <InputLabel
                                id="user"
                                className="Input"
                                style={{
                                    color: "rgba(12,11,69,255)",
                                    display: "flex",
                                    fontSize: "15px",
                                    marginRight:'10px',
                                    fontWeight: "bold",
                                }}
                            >
                                Coach:
                            </InputLabel>
                            <Autocomplete
                                disableClearable
                                id="combo-box-demo"
                                className={matches ? 'w-100' : ''}
                                value={userValue}
                                onChange={(event, newValue) => {
                                    setUserSearchValue('')
                                    setuserValue(newValue)
                                    loadDiet(newValue.id)
                                }}
                                options={dataUserFilter?.map(items => { return { label: items.coach_name, id: items.id } })}
                                sx={{ width: 300, backgroundColor: '#fff', marginRight: '20px' }}
                                renderInput={(params) => <TextField variant="outlined" placeholder='Coach' value={userSearchValue} {...params} onChange={searchFilter} />}
                            />
                        </div>
                    }
                </Box>
            </Container>
            <Container >
                <Paper maxWidth="lg" className={className.Paper}>
                    <Stack direction="row" className='diet-tab' spacing={2}>
                        {
                            dietPlanDays?.map(items => {
                                return <Button variant="outlined" className={`${className.days} ${tab === items.id ? 'active' : ''}`} onClick={handleTab} value={items.id} key={items.id}>
                                    {items.name}
                                </Button>
                            })
                        }
                    </Stack>
                    <Container className={className.container}>
                        <Card style={{ marginTop: "70px" }}
                            sx={{ margin: '0px auto', boxShadow: '0px 0px 14px 0px #c3c3c3', borderRadius: '7px' }}>
                            {
                                loader ?
                                    <Box sx={{ textAlign: 'center' }}>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    data?.map(items => {
                                        return tab === items.weekdays && (items.dietTime?.length ? <CardContentList items={items} key={items.id} /> : <Box component='h4' sx={{ margin: 0, padding: '30px 0', textAlign: 'center' }}>No plan has been assigned to you at the moment.</Box>)
                                    })
                            }
                        </Card>
                        {
                            !data?.some(items => items.weekdays === tab) ? <Box component='h4' sx={{ margin: 0, textAlign: 'center' }}>
                                No plan has been assigned to you at the moment.
                            </Box> : ''
                        }
                    </Container>
                </Paper>
            </Container>
        </AppLayout>
    )
}

export default DietPlan;
