import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Paper, Grid, TextField, InputLabel, Container, MenuItem, Select, Switch, Box } from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import AppLayout from "../../layout/appLayout";
import CircularProgress from "@mui/material/CircularProgress";
import Fetch from "../../common/fetch";
import { toast, ToastContainer } from "react-toastify";
import { arrayString } from "../../common/utilits";
import Back from "../../component/backIco";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

const emailRegx = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
let validateError = {
  name: yup
    .string()
    .max(25, "Must be 25 characters or less.")
    .required("Name is required.")
    .matches(/^[A-Za-z ]*$/, "Only alphabets are required."),
  email: yup
    .string()
    .email("Email is invalid.")
    .required("Email is required.")
    .matches(emailRegx, "Invalid Email ID..."),
  gender: yup.string().required("Please select gender"),
  phone_no: yup
    .string()
    .matches(phoneRegExp, "Phone number must contains only digits.")
    .min(10, "Phone number should not be less than 10 digits.")
    .max(10, "Phone number should not be more than 10 digits.")
    .required("Phone number is required."),
  location: yup
    .string()
    .max(50, "Must be 50 characters or less.")
    .required("Location is required."),
}

const Addnew = () => {
  let navigate = useNavigate();
  const param = useParams()
  validateError = param.id === 'add' ? {
    ...validateError, password: yup
      .string()
      .matches(
        PASSWORD_REGEX,
        " Password must have at least 8 characters and the combination of the following: uppercase letter, lowercase letter, numbers, and symbols."
      )
      .required("Password is required."),
  } : validateError
  const validationSchema = yup.object().shape(validateError);
  const [error, seterror] = useState();
  const [status,setStatus]=useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    name: "",
    email: "",
    phone_no: "",
    gender: '',
    location: "",
  });
  useEffect(() => {
    if (param.id !== 'add') {
      loadUser(param.id)
      setState({
        name: "",
        email: "",
        phone_no: "",
        gender: '',
        location: "",
      })
    } else {
      setState({
        ...state,
        password: "",
      })
    }
  }, [param])
  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  };
  const onSubmit = (e) => {
    if (formik.isValid) {
      setIsLoading(true);
      let val = { ...state }
      if (param.id !== 'add') {
        delete val.email
      }
      Fetch(`sports/owner/${param.id === 'add' ? '' : param.id + '/'}`, val, { method: param.id === 'add' ? 'post' : 'patch', inFormData: true }).then(res => {
        setIsLoading(false);
        if (res.status) {
          toast.success(`Centre Owner ${param.id === 'add' ? 'Added' : 'Updated'} Successfully.`, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/centreowner/");
          }, 3000)
        } else {
          let resErr = arrayString(res)
          toast.error(resErr.error, {
            hideProgressBar: false,
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
    }
  };
  const loadUser = (id) => {
    Fetch(`sports/owner/${id}/`).then(res => {
      if (res.status) {
        const { data } = res
        setStatus(data?.status)
        setState({
          name: data?.name,
          email: data?.email,
          phone_no: data?.phone_no,
          location: data?.location || '',
          gender: data?.gender,
        });
      }
    })
  };
  const formik = useFormik({
    initialValues: state,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });
  const onChangeStatus=(e)=>{
    setStatus(e.target.checked)
    Fetch('users/deactivate-user/',{user_id:Number(param.id),role:'Owner'},{method:'post'}).then(res=>{
      if(res.status){
        toast.success(`Owner has been ${e.target.checked?'Activated':'Deactivated'} Successfully.`, {
          hideProgressBar: false,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  }
  const classes = useStyles();
  return (
    <AppLayout style={{ marginBottom: "50px" }}>
      <ToastContainer />
      <Container>
        <h3 className="flex align-center" style={{ padding: "10px 10px 10px 0px" }}><Back />{param.id === 'add' ? 'Add' : 'Edit'} Centre Owner</h3>
        <Paper elevation={3}>
          <div
            className={classes.root}
            style={{ padding: "20px", marginBottom: "80px" }}
          >
            <form method="POST" noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    margin="normal"
                    required
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    name="name"
                    onBlur={formik.handleBlur}
                    onKeyUp={onChange}
                    onChange={formik.handleChange}
                    autoComplete="name"
                    variant="outlined"
                    type="text"
                    value={formik.values.name}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Email
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    margin="normal"
                    name="email"
                    disabled={param.id !== 'add'}
                    onBlur={formik.handleBlur}
                    onKeyUp={onChange}
                    onChange={formik.handleChange}
                    type="email"
                    autoComplete="off"
                    value={formik.values.email}
                    variant="outlined"
                  />
                  <p style={{ color: "red", margin: "0px", fontSize: "12px" }}>
                    {error}
                  </p>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    id="gender"
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    error={Boolean(
                      formik.touched.gender && formik.errors.gender
                    )}
                    fullWidth
                    variant="outlined"
                    required
                    helperText={formik.touched.gender && formik.errors.gender}
                    name="gender"
                    value={formik.values.gender}
                    style={{ marginTop: "16px" }}
                    onChange={(e) => {
                      formik.handleChange(e);
                      onChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>--Select--</em>
                    </MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {/* {mes && <div style={{ color: "red" }}>{mes}</div>} */}
                  {formik.touched.gender && (
                    <div className="MuiFormHelperText-root Mui-error Mui-required">{formik.errors.gender}</div>
                  )}
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Phone No.
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 10 }}
                    error={Boolean(
                      formik.touched.phone_no && formik.errors.phone_no
                    )}
                    margin="normal"
                    required
                    fullWidth
                    helperText={
                      formik.touched.phone_no && formik.errors.phone_no
                    }
                    onBlur={formik.handleBlur}
                    onKeyUp={onChange}
                    value={formik.values.phone_no}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    name="phone_no"
                    variant="outlined"
                    type="tel"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Location
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                      formik.touched.location && formik.errors.location
                    )}
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                    margin="normal"
                    required
                    fullWidth
                    onBlur={formik.handleBlur}
                    value={formik.values.location}
                    onKeyUp={onChange}
                    onChange={formik.handleChange}
                    name="location"
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                {param.id !== 'add' ? <Grid item sm={12} md={12}>
                  <Box className='swtichBox'>
                    <label>Activate</label>
                    <Switch checked={status} onChange={onChangeStatus}/>
                    <label>Deactivate</label>
                  </Box>
                </Grid>:null}
                {param.id === 'add' ? <Grid item sm={12} md={4}>
                  <InputLabel
                    className="Input"
                    style={{
                      color: "rgba(12,11,69,255)",
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Password
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    margin="normal"
                    required
                    fullWidth
                    location="password"
                    onBlur={formik.handleBlur}
                    onKeyUp={onChange}
                    onChange={formik.handleChange}
                    name="password"
                    type="password"
                    autoComplete="password"
                    variant="outlined"
                  />
                </Grid> : null}
                <Grid item sm={12} md={12}>
                  <div style={{ textAlign: "center", marginTop: "100px" }}>
                    <Button
                      variant="contained"
                      // disabled={isSubmitting}
                      type="submit"
                      style={{
                        backgroundColor: "#232b58",
                        textTransform: 'uppercase',
                        color: "#fff",
                        borderRadius: "25px",
                        width: "290Px",
                        padding: "13px",
                        fontSize: "17px",
                      }}
                      // onClick={(e) => onSubmit(e)}
                      className="btn-submit"
                    >
                      {isLoading ? <CircularProgress Shrink /> : `${param.id === 'add' ? 'Add Centre Owner' : 'Update'}`}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </AppLayout>
  );
};
export default Addnew;