import React, { useEffect, useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Badge, MenuItem, Menu, Avatar, useMediaQuery } from "@material-ui/core";
import { AccountCircle, Notifications, MoreVert, KeyboardArrowDown, Close } from "@material-ui/icons";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import "./Header.css";
import DropDownMenu from "../../dropdown";
import Fetch from "../../../common/fetch";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    fontWeight: 500,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",

    borderRadius: "25px",

    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: 'center'
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Header({ handleMobileDrawerOpen }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const signOut = () => {
    localStorage.clear();
    navigate("/");
  };
  const [openNotify, setOpenNotify] = useState(false);
  const [list, setList] = useState([])
  const [notifyCount, setnotifyCount] = useState(0)
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const handleClickNotify = () => {
    setOpenNotify(false);
  };
  const handleUpdateNotify = () => {
    setOpenNotify(!openNotify);
    if (list?.count && !openNotify) {
      Fetch('notifications/update-notification/', { is_read: true }, { method: 'post' }).then(res => {
        if (res.status) {
          setnotifyCount(0)
        }
      })
    }
  }
  useEffect(() => {
    Fetch('notifications/notification-list').then(res => {
      if (res.status) {
        setList(res.data)
        setnotifyCount(res.data?.count)
      }
    })
  }, [])

  useEffect(() => {
    setName(localStorage.name)
    setRole(localStorage.role)
  }, [localStorage.name])

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ marginTop: "35px" }}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      {/* <Link href="/" style={{ textDecoration: "none" }}> */}
      <a style={{ textDecoration: "none", color: '#242b58' }} href="/">
        <MenuItem className="btn-logout" onClick={signOut}>Log Out</MenuItem>
      </a>
      {/* /    </Link> */}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={signOut}>
        {/* <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton> */}
        <p>Log Out</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" style={{ backgroundColor: "#232b58" }}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            {/* <MenuIcon /> */}
            Centre Management
          </IconButton>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {role !== 'Admin' ? <div className="position-relative">
              <IconButton
                id="basic-button"
                style={{ color: '#fff', marginRight: 10 }}
                aria-controls={openNotify ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openNotify ? 'true' : undefined}
                onClick={handleUpdateNotify}
              >
                <Badge badgeContent={notifyCount} color="primary">
                  <Notifications />
                </Badge>
              </IconButton>
              <NotificationModel list={list?.notification} open={openNotify} handleClick={handleClickNotify} />
            </div> : null}
            <IconButton
              edge="end"
              style={{ padding: 0 }}
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar
              // alt="Remy Sharp"
              // src="https://www.shaadidukaan.com/vogue/wp-content/uploads/2019/08/hug-kiss-images.jpg"
              />
              <p style={{ marginLeft: "10px", fontSize: "15px" }}>
                <div className="flex align-center">
                  {name === "null" ? 'Super Admin' : name}
                  <KeyboardArrowDown />
                </div>
                <p className="role-name">{role}</p>
              </p>

            </IconButton>
          </div>
          <div className={`${classes.sectionMobile}`}>
            {role !== 'Admin' ? <div>
              <IconButton
                id="basic-button"
                style={{ color: '#fff', marginRight: 10 }}
                aria-controls={openNotify ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openNotify ? 'true' : undefined}
                onClick={handleUpdateNotify}
              >
                <Badge badgeContent={notifyCount} color="primary">
                  <Notifications />
                </Badge>
              </IconButton>
              <NotificationModel list={list?.notification} open={openNotify} handleClick={handleClickNotify} />
            </div> : null}
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileDrawerOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
function NotificationModel({ list, open, handleClick }) {
  const mobile = useMediaQuery('(max-width:500px)')
  return <DropDownMenu
    open={open}
    onClose={handleClick}
    className="notification"
  >
    <div className="notification-head flex align-center justify-between">
      <h3>Notifications</h3>
      {
        mobile && <IconButton
          aria-label="show more"
          aria-haspopup="true"
          className="p0"
          onClick={handleClick}
          color="inherit"
        ><Close /></IconButton>
      }
    </div>
    <div className="notification-list">
      {list?.length ? <ul>
        {list?.map(item => <li key={item.id}>
          <strong>{item.message}</strong>
          {/* <p>{moment(item.created_at).format("MMM Do YYYY")}</p> */}
          <p>{moment(item.created_at).fromNow()}</p>
        </li>)}
      </ul> :
        null
      }
    </div>
  </DropDownMenu>
}