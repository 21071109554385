import AppLayout from "../../layout/appLayout";
import React from "react";
import { IconButton, Button, TableRow, TableHead, TableCell, TableBody, Table, Container, Grid, CircularProgress } from "@material-ui/core";
import { AddCircleOutlineOutlined, Delete, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { DialogActions, Dialog, DialogContent, DialogContentText, DialogTitle, TableContainer, Stack } from "@mui/material";
import Pagination from "../../component/Page/Pagination";
import Fetch from "../../common/fetch";
import { makeStyles } from "@material-ui/core/styles";
import { toast, ToastContainer } from "react-toastify";
import { arrayString, paginationSize } from "../../common/utilits";
import { dietPlanDays } from "../../json/menu";
import TypeSearch from "../../component/search";
const useStyles = makeStyles((theme) => ({
    days: {
        boxShadow: 'unset',
        borderColor: '#a2a2a2 !important',
        color: '#717171 !important',
        fontSize: '13px !important',
        padding: '7px 22px !important',
        [theme.breakpoints.up("md")]: {
            minWidth: "100px !important",
        },
        '&:hover': {
            backgroundColor: '#242b58 !important',
            color: '#fff !important'
        },
        '&.active': {
            backgroundColor: '#242b58',
            color: '#fff !important',
            '&:hover': {
                backgroundColor: '#242b58',
                color: '#fff !important'
            },
        }
    }
}))
export default function DietCoach() {
    const className = useStyles()
    const [tab, setTab] = useState(1)
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(true)
    const [setid, SetsetId] = useState();
    const [page, setPage] = useState(1)
    useEffect(() => {
        document.title = "Centre";
        loadUsers(1, 1);
    }, []);
    const handleTab = (id) => {
        setTab(id);
        loadUsers(page, id);
    }
    const handleClickOpen = (id) => {
        SetsetId(id)
        setOpen(!open)
    }
    const loadUsers = (page, day, search) => {
        Fetch(`sports/dite-plan/${page ? '?page=' + page : ''}${day ? '&day=' + day : ''}${search ? '&search=' + search : ''}`).then(res => {
            setLoader(false)
            if (res.status) {
                setPage(page)
                setData(res.data);
            }
        })
    };
    const deleteUser = () => {
        Fetch(`sports/delete-diet/${setid}/`, null, { method: 'delete' }).then(res => {
            if (res.status) {
                handleClickOpen(null)
                loadUsers(page, tab)
                toast.success("Plan Deleted Succesfully.", {
                    hideProgressBar: false,
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                let resErr = arrayString(res)
                toast.error(resErr.error, {
                    hideProgressBar: false,
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        })
    }
    return <AppLayout>
        <ToastContainer />
        <Container maxWidth="lg">
            <Grid container spacing={3} alignItems='center' className="owners py-5">
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    <h3
                        className="topheading
            "
                    >
                        {" "}
                        Plan
                    </h3>
                </Grid>
                <Grid
                    className="buttons headSearch"
                    item
                    xs={12}
                    sm={6}
                >
                    <TypeSearch handleChange={(val) => loadUsers(1, 1, val)} />
                    <Link to={`/plan/add/${tab}`} style={{ textDecoration: "none" }}>
                        <Button
                            className="add-btn"
                            variant="contained"
                        >
                            <AddCircleOutlineOutlined style={{ marginRight: "6px" }} />
                            Add Plan
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Container>
        <Container>
            <div className="WidgetLg" style={{ marginBottom: "85px" }}>
                <TableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" style={{width:"40%"}}>S.No.</TableCell>
                                <TableCell align="center" style={{width:"20%"}}>Users</TableCell>
                                {/* <TableCell align="left">Created Date</TableCell> */}
                                {/* <TableCell align="left">Meal</TableCell> */}
                                <TableCell align="center" style={{width:"40%"}}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loader ?
                                    <TableRow>
                                        <TableCell colSpan={6} align="center"><CircularProgress /></TableCell>
                                    </TableRow>
                                    :
                                    data?.results?.length ?
                                        data?.results?.map((admin, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell align="center">{(page - 1) * paginationSize + i + 1}</TableCell>
                                                    <TableCell align="center">
                                                        {admin?.user_name}
                                                    </TableCell>
                                                    {/* <TableCell align="left">
                                                        {admin?.day}
                                                    </TableCell> */}
                                                    {/* <TableCell align="left" >{admin?.dietTime}</TableCell> */}
                                                    {/* 
                                            <TableCell align="left" width={'300px'}>{admin?.dietDetails?.length &&
                                                admin?.dietDetails[0]?.length > 60 ?
                                                `${admin?.dietDetails[0].substring(0, 60)}...` : admin?.dietDetails[0]

                                            }</TableCell> */}
                                                    <TableCell align="center">
                                                        <div className="flex align-center justify-center">
                                                            <Link to={`/plan/${admin?.user_id}/${tab}`}>
                                                                <IconButton
                                                                    variant="contained"
                                                                    type="submit"
                                                                    title="Edit"
                                                                    style={{
                                                                        padding: "0",
                                                                        boxShadow: "none",
                                                                        border: "none",
                                                                        background: "none",
                                                                        minWidth: "0px",
                                                                    }}
                                                                >
                                                                    <Edit
                                                                        style={{ color: "#17a248", margin: "-5px" }}
                                                                    />
                                                                </IconButton>
                                                            </Link>
                                                            <IconButton
                                                                onClick={() => handleClickOpen(admin?.user_id)}
                                                                title="Delete"
                                                                style={{
                                                                    padding: "0",
                                                                    boxShadow: "none",
                                                                    border: "none",
                                                                    background: "none",
                                                                    minWidth: "0px",
                                                                }}
                                                            >
                                                                <Delete style={{ color: "red", margin: "8px" }} />
                                                            </IconButton>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">No Data Found</TableCell>
                                        </TableRow>
                            }
                        </TableBody>
                        <Dialog open={open} onClose={() => handleClickOpen(null)}>
                            <DialogTitle>Delete Plan</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete this Plan?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="Buttonss">
                                <Button onClick={() => handleClickOpen(null)}>No</Button>
                                <Button onClick={() => deleteUser()}>Yes</Button>
                            </DialogActions>
                        </Dialog>
                    </Table>
                </TableContainer>
                {data?.results?.length ? <Pagination data={data} sendPageNumber={loadUsers} /> : null}
            </div>
        </Container>
    </AppLayout>
}