import React from 'react'
import { Container, Grid, Paper, Card, CardContent } from '@mui/material';
import Back from "../../component/backIco";
import AppLayout from '../../layout/appLayout';
function Subscription() {
    return (
        <AppLayout>
            <Container maxWidth="lg">
                <Grid container className="owners py-5">
                    <Grid item xs={12} sm={3}>
                        <h3 className='flex align-center'><Back /> Subscription Plans </h3>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg">
                <Paper elevation={3} maxWidth="lg" style={{ padding: "30px", marginBottom: "50px" }}>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={12} style={{ padding: "0px", marginTop: "0px", marginBottom: "20px" }}>
                            <Card
                                sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <CardContent style={{ paddingRight: "25px", paddingLeft: "25px", width: "248px", backgroundColor: "#f1f1f1" }}>
                                    <div style={{ textAlign: "center", color: "#122A4D" }}>
                                        <h1 style={{ fontSize: "40px", marginBottom: "0px", marginTop: "0px" }}>01</h1>
                                        <h3 style={{ marginBottom: "0px", fontSize: "23px", marginTop: "10px" }}>Month Plan</h3>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-29px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item md={12} lg={3} style={{ padding: "0px", marginTop: "0px", marginBottom: "20px" }}>
                            <Card
                                sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <CardContent style={{ paddingRight: "25px", paddingLeft: "25px", width: "248px", backgroundColor: "#f1f1f1" }}>
                                    <div style={{ textAlign: "center", color: "#122A4D" }}>
                                        <h1 style={{ fontSize: "40px", marginBottom: "0px", marginTop: "0px" }}>03</h1>
                                        <h3 style={{ marginBottom: "0px", fontSize: "23px", marginTop: "10px" }}>Month Plan</h3>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-29px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item md={12} lg={3} style={{ padding: "0px", marginTop: "0px", marginBottom: "20px" }}>
                            <Card
                                sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <CardContent style={{ paddingRight: "25px", paddingLeft: "25px", width: "248px", backgroundColor: "#f1f1f1" }}>
                                    <div style={{ textAlign: "center", color: "#122A4D" }}>
                                        <h1 style={{ fontSize: "40px", marginBottom: "0px", marginTop: "0px" }}>06</h1>
                                        <h3 style={{ marginBottom: "0px", fontSize: "23px", marginTop: "10px" }}>Month Plan</h3>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-29px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item md={12} lg={3} style={{ padding: "0px", marginTop: "0px", marginBottom: "20px" }}>
                            <Card
                                sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <CardContent style={{ paddingRight: "25px", paddingLeft: "25px", width: "248px", backgroundColor: "#f1f1f1" }}>
                                    <div style={{ textAlign: "center", color: "#122A4D" }}>
                                        <h1 style={{ fontSize: "40px", marginBottom: "0px", marginTop: "0px" }}>01</h1>
                                        <h3 style={{ marginBottom: "0px", fontSize: "23px", marginTop: "10px" }}>Year Plan</h3>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-29px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                            <Card style={{ padding: "9px", marginTop: "-16px", paddingRight: "25px", paddingLeft: "25px", width: "248px" }} sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                                <div style={{ textAlign: "center" }}>
                                    <p>Lorem ipsum dolor sit amed</p>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </AppLayout>
    )
}
export default Subscription